import { yupResolver } from '@hookform/resolvers/yup'
import {
  CalendarBlank,
  ClockClockwise,
  Info,
  TreePalm,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledSelect from '../../../components/ControlledSelect'
import PolicyAccrualInfo from '../../../components/policy-accrual-info'
import PolicyRequestSettings from '../../../components/policy-request-settings'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { SideMenu } from '../../../components/ui/side-menu'
import WorkingDaysView from '../../../components/working-days-view'
import { useFetch } from '../../../helpers/hooks'
import {
  changeTimeOffPolicy,
  getContractTimeOff,
  getPolicyCyclesPreview,
  getTimeOffPolicies,
} from '../../../services/api-time-off-policies'
import { track } from '../../../utils/analytics'
import { formatDays } from '../../../utils/formatters/format-days'
import {
  EARNING_DATE_TYPE,
  labelFromType,
} from '../../CompanySetting/components/time-off-policies-tab/manage-policy-workers'
import { DetailsList } from '../../CompanySetting/components/time-off-policies-tab/view-policy'
import { TIMEOFF_EVENTS } from '../../new-time-off-policy/events'
import { ConfirmFormField } from '../CreateContract/components/confirm-field'
import { BalanceItem, PolicyBalancePreview } from './assign-time-off-policy'
import { POLICY_ACTION_TYPE } from './time-off-stats'
import ControlledInput from '../../../components/ControlledInput'
import { DATE_PICKER_FORMAT_2 } from '../../../utils/formatters/date-picker-date-format'

const changePolicyFormId = 'assign-policy-form'
export function ChangePolicySideMenu({
  contract,
  policy,
  toggle,
  isOpen,
  refresh,
}) {
  const contractId = contract?.id

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(
      yup.object().shape({
        policy_id: yup.number().required('Please select a policy'),
        earning_date: yup
          .string()
          .nullable()
          .test({
            name: 'earning_date_test',
            test: (value, { options }) => {
              const localSelectedPolicy = policies?.find(
                (p) => p.id === options?.parent?.policy_id,
              )
              const isLocalSelectedPolicyAccrued =
                localSelectedPolicy?.type?.is_accrued === 1

              // If the selected policy is accrued, the earning date is required
              const earningDateIsRequired =
                !value && isLocalSelectedPolicyAccrued

              return !earningDateIsRequired
            },
            message: 'Please select an option',
          }),
        custom_earning_date: yup.date().when('earning_date', {
          is: EARNING_DATE_TYPE.CUSTOM,
          then: (schema) => schema.required('Please select a custom date'),
          otherwise: (schema) => schema.nullable(),
        }),
        [EARNING_DATE_TYPE.EARNING_START_DAYS]: yup
          .number()
          .typeError('Please insert a number')
          .when('earning_date', {
            is: EARNING_DATE_TYPE.EARNING_START_DAYS,
            then: (schema) => schema.required('Please insert amount of days'),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
  })

  const {
    policy_id: policyId,
    earning_date: earningDate,
    custom_earning_date: customEarningDate,
  } = useWatch({ control })

  const { startFetch: changePolicy, isLoading: changingPolicy } = useFetch({
    action: changeTimeOffPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to change policy')
      } else {
        toastr.success('Policy changed successfully')
        toggle({ reload: true })
        refresh()
        track(TIMEOFF_EVENTS.CHANGED_POLICY, {
          target: selectedPolicy.type.name,
          source: policy.type.name,
          custom_accrual_date: isCustomEarningDate,
        })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function onSubmit(values) {
    let startDate
    let days
    if (values.earning_date === EARNING_DATE_TYPE.CUSTOM) {
      startDate = customEarningDate
    }
    if (values.earning_date === EARNING_DATE_TYPE.EARNING_START_DAYS) {
      days = values[EARNING_DATE_TYPE.EARNING_START_DAYS]
    }
    const body = {
      from_timeoff_policy_id: policy?.policy?.id,
      to_timeoff_policy_id: values.policy_id,
      contract_id: contractId,
      start_date: startDate,
      [EARNING_DATE_TYPE.EARNING_START_DAYS]: days,
    }

    changePolicy(body)
  }

  const { data: existingPolicies, isLoading: existingPoliciesLoading } =
    useFetch(
      {
        action: getContractTimeOff,
        body: { contract_id: contractId },
        autoFetch: !!contractId && isOpen,
        onError: (error) => {
          toastr.error(error)
        },
      },
      [contractId, isOpen],
    )

  const {
    data: policies,
    isLoading: policiesLoading,
    completed: policiesCompleted,
  } = useFetch(
    {
      action: getTimeOffPolicies,
      autoFetch: isOpen,
      onError: (error) => {
        toastr.error(error)
      },
    },
    [isOpen],
  )

  const selectedPolicy = policies?.find((p) => p.id === policyId)
  const isSelectedPolicyAccrued = selectedPolicy?.type?.is_accrued === 1

  const isCurrentPolicyAccrued = policy?.type?.is_accrued === 1

  const isCustomEarningDate = earningDate === 'custom_date'

  const formattedContractStartDate = format(
    new Date(contract?.start_date),
    'yyyy-MM-dd',
  )

  const { data: cyclesPreview, isLoading: cyclesPreviewLoading } = useFetch(
    {
      action: getPolicyCyclesPreview,
      autoFetch:
        isSelectedPolicyAccrued &&
        (!isCustomEarningDate || (isCustomEarningDate && customEarningDate)),
      body: {
        id: selectedPolicy?.id,
        start_date: isCustomEarningDate
          ? customEarningDate
          : formattedContractStartDate,
        policy_id: policyId,
        contract_id: contractId,
        balance_id: policy.id,
      },
    },
    [selectedPolicy?.id, earningDate, customEarningDate, isCustomEarningDate],
  )

  const policyOptions = policies
    ?.filter((policyItem) => {
      const policyType = policyItem.type.id
      const policyId = policyItem.id

      const currentPolicyType = policy?.type?.id
      const currentPolicyId = policy?.policy?.id

      if (policyType === currentPolicyType) {
        return policyId !== currentPolicyId
      } else {
        const policyAlreadyAssigned = !existingPolicies
          ?.map((p) => p.policy.id)
          .includes(policyItem.id)

        return policyAlreadyAssigned
      }
    })
    ?.map((policy) => ({
      label: policy.name,
      value: policy.id,
      description: `${policy.type.name} | ${
        policy.type.is_accrued ? 'Accrual' : 'Non-accrual'
      }`,
    }))

  const requiresCustomEarningDate = isCustomEarningDate && !customEarningDate

  const isOptionDisabled = (item) => {
    const policyItem = policies.find((policy) => policy.id === item.value)
    return policy.is_accrual !== policyItem.is_accrual
  }

  const getActionType = () => {
    const difference = cyclesPreview?.balance_on_assigning - policy?.days
    if (difference > 0) return POLICY_ACTION_TYPE.ADDITION
    if (difference < 0) return POLICY_ACTION_TYPE.DEDUCTION
    return ''
  }

  return (
    <SideMenu
      isOpen={isOpen}
      onClose={toggle}
      className='!tw-max-w-[480px]'
      itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[auto_1fr_auto]'
    >
      <SideMenu.Header toggle={toggle}>
        Change “{policy?.policy?.name}”
      </SideMenu.Header>
      <SideMenu.Body>
        {policiesLoading || !policiesCompleted || existingPoliciesLoading ? (
          <Loader minHeight='100%' />
        ) : (
          <form id={changePolicyFormId} onSubmit={handleSubmit(onSubmit)}>
            <ControlledSelect
              control={control}
              name='policy_id'
              label='New policy'
              placeholder='Select new policy'
              options={policyOptions}
              transform={{
                output: (newValue) => {
                  const foundPolicy = policies?.find(
                    (policy) => policy.id === newValue?.value,
                  )
                  // Default accrued policy to contract start date
                  if (foundPolicy?.type?.is_accrued === 1) {
                    setValue(
                      'earning_date',
                      EARNING_DATE_TYPE.EARNING_START_DAYS,
                    )
                  } else {
                    setValue('earning_date', null)
                  }
                  setValue('custom_earning_date', null)
                  setValue(
                    EARNING_DATE_TYPE.EARNING_START_DAYS,
                    foundPolicy?.[EARNING_DATE_TYPE.EARNING_START_DAYS],
                  )
                  return newValue
                },
              }}
              isOptionDisabled={isOptionDisabled}
            />
            <AnimatePresence mode='wait'>
              {!policyId ? null : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  key={selectedPolicy?.id}
                >
                  <DetailsList
                    className='tw-px-0'
                    title='Policy details'
                    titleClassName='tw-px-0'
                    bodyClassName='tw-px-0'
                    items={[
                      {
                        icon: <TreePalm size={24} />,
                        label: 'Type',
                        value: selectedPolicy?.type?.name,
                      },
                      isSelectedPolicyAccrued && {
                        icon: <CalendarBlank size={24} />,
                        label: 'Time off amount',
                        value:
                          formatDays(selectedPolicy?.accrual_days) + ' / Year',
                      },
                      {
                        icon: <ClockClockwise size={24} />,
                        label: 'Accrual frequency',
                        value: selectedPolicy?.accrual_frequency
                          ? labelFromType(selectedPolicy?.accrual_frequency)
                          : 'Non-Accrued',
                      },
                    ]}
                  />

                  <hr className='-tw-mx-6 tw-mb-6 tw-mt-0' />
                  <WorkingDaysView days={selectedPolicy.working_days} />
                  <hr className='-tw-mx-6 tw-my-6' />

                  {isSelectedPolicyAccrued && (
                    <PolicyAccrualInfo
                      policy={selectedPolicy}
                      bodyClassName='tw-px-0'
                    />
                  )}

                  <PolicyRequestSettings
                    isOpenByDefault
                    policy={selectedPolicy}
                    bodyClassName='tw-px-0'
                  />

                  {!isSelectedPolicyAccrued ? null : (
                    <>
                      <hr className='-tw-mx-6 tw-my-6' />
                      <ConfirmFormField
                        control={control}
                        error={errors.earning_date}
                        name='earning_date'
                        title='Earning date'
                        description='When does the worker start earning?'
                        fieldOptions={[
                          {
                            label: 'Days after contract start',
                            value: EARNING_DATE_TYPE.EARNING_START_DAYS,
                          },
                          {
                            label: 'Custom date',
                            value: EARNING_DATE_TYPE.CUSTOM,
                          },
                        ]}
                        className='tw-mt-3'
                        innerClassName={
                          isCustomEarningDate && 'tw-rounded-b-none'
                        }
                        transform={{
                          output: (event) => {
                            setValue('custom_earning_date', null)
                            setValue('earning_start_days', null)

                            return event
                          },
                        }}
                      />
                      <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
                        {!isCustomEarningDate ? (
                          <>
                            <ControlledInput
                              control={control}
                              name={EARNING_DATE_TYPE.EARNING_START_DAYS}
                              postFix='Calendar days'
                              type='number'
                              placeholder='Insert amount'
                            />
                            <div className='tw-mt-2 tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-text-80'>
                              <Info size={16} />
                              <span>
                                Policy setting:{' '}
                                {selectedPolicy?.[
                                  EARNING_DATE_TYPE.EARNING_START_DAYS
                                ] ?? 0}{' '}
                                days after the contract start date
                              </span>
                            </div>
                          </>
                        ) : (
                          <ControlledDatePicker
                            control={control}
                            name='custom_earning_date'
                            id='custom_earning_date'
                            placeholder='Earning date'
                            label='Earning date'
                            dateFormat={DATE_PICKER_FORMAT_2}
                          />
                        )}
                      </div>
                    </>
                  )}

                  {isCurrentPolicyAccrued || isSelectedPolicyAccrued ? (
                    <>
                      <hr className='-tw-mx-6 tw-my-6' />
                      <h4 className='tw-text-base tw-font-bold'>
                        Balance preview
                      </h4>

                      <BalanceItem
                        note='Current balance'
                        created={policy?.policy?.name}
                        newBalance={policy?.days}
                        isBalanceUnlimited={!isCurrentPolicyAccrued}
                      />

                      <BalanceItem
                        note='Next balance'
                        created={selectedPolicy?.name}
                        days={
                          cyclesPreview?.balance_on_assigning - policy?.days
                        }
                        newBalance={cyclesPreview?.balance_on_assigning}
                        type={getActionType()}
                        isBalanceUnlimited={!isSelectedPolicyAccrued}
                      />
                    </>
                  ) : null}

                  {!isSelectedPolicyAccrued ? null : (
                    <>
                      <hr className='-tw-mx-6 tw-my-6' />
                      <h4 className='tw-text-base tw-font-bold'>
                        Accrual balance preview
                      </h4>

                      {!earningDate || requiresCustomEarningDate ? (
                        <div className='tw-h-[390px] tw-pt-4'>
                          Please select an earning date
                        </div>
                      ) : cyclesPreviewLoading ? (
                        <div className='tw-h-[390px] tw-pt-4'>
                          Loading accrual balance preview ...
                        </div>
                      ) : (
                        <PolicyBalancePreview
                          cyclesPreview={cyclesPreview?.cycles}
                        />
                      )}
                    </>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </form>
        )}
      </SideMenu.Body>
      <SideMenu.Footer>
        <Button
          color='light'
          outline
          type='button'
          onClick={toggle}
          disabled={changingPolicy}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={changePolicyFormId}
          disabled={changingPolicy || !selectedPolicy}
          loading={changingPolicy}
        >
          Save
        </Button>
      </SideMenu.Footer>
    </SideMenu>
  )
}
