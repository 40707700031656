import React from 'react'
import { Card, Input } from 'reactstrap'
import {
  CaretDown,
  CaretUp,
  EnvelopeSimple,
  Play,
  TrashSimple,
} from '@phosphor-icons/react'
import { cn } from 'ui'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import { TagsInput } from '../../../remotepass-refers/components/sharing-section'
import { ActionEmailBody } from './action-email-body'

export function Action(props) {
  const {
    id,
    index,
    stepsField: { fields: steps, remove },
  } = props
  const { watch, setValue } = useFormContext()
  const { errors } = useFormState()
  const focused = watch('focusedNode') === id
  const isFirstAction = steps.filter((s) => s.type === 'action')?.[0]?.id === id

  return (
    <div
      className={cn(
        'tw-rounded-md',
        focused ? 'tw-border-2 tw-border-primary-60' : undefined,
        errors?.steps?.[index] ? 'tw-border-2 tw-border-red' : undefined,
      )}
    >
      <Card className='!tw-mb-0 tw-flex tw-flex-col'>
        <div className='tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4'>
          <div className='tw-rounded tw-bg-secondary-20 tw-p-2'>
            <Play size={25} className='tw-text-secondary' />
          </div>
          <div className='tw-flex tw-flex-1 tw-flex-col'>
            <span className='tw-font-medium'>
              {isFirstAction ? 'Then do this' : 'And do this'}
            </span>
            <span>Select action</span>
          </div>
          {!isFirstAction ? (
            <button
              className='tw-rounded tw-bg-secondary-20 tw-p-2'
              onClick={() => remove(index)}
            >
              <TrashSimple size={25} className='tw-text-secondary' />
            </button>
          ) : null}
          <button
            onClick={() => setValue('focusedNode', focused ? null : id)}
            className='tw-rounded tw-bg-primary-20 tw-p-2'
          >
            {focused ? (
              <CaretUp size={25} className='tw-text-primary' />
            ) : (
              <CaretDown size={25} className='tw-text-primary' />
            )}
          </button>
        </div>
        {focused ? (
          <>
            <div className='tw-h-0.5 tw-bg-secondary-20' />
            <div className='tw-flex tw-flex-col tw-gap-3 tw-p-4'>
              <div className='tw-flex tw-flex-row tw-items-center tw-gap-2'>
                <EnvelopeSimple size={20} className='tw-text-primary' />
                <span className='tw-font-bold'>Customize your email</span>
              </div>

              <Controller
                render={({ field: { value = [], onChange } }) => (
                  <TagsInput setTags={onChange} tags={value} placeholder='To' />
                )}
                name={`steps.${index}.to`}
              />

              <Controller
                render={({ field: { value = '', onChange, onBlur } }) => (
                  <Input
                    id='input'
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder='Email Subject'
                  />
                )}
                name={`steps.${index}.subject`}
              />

              <ActionEmailBody index={index} />
            </div>
          </>
        ) : null}
      </Card>
    </div>
  )
}
