import {
  Asterisk,
  Bank,
  Coin,
  EnvelopeSimple,
  Flag,
  GlobeSimple,
  IdentificationBadge,
  IdentificationCard,
  MapPin,
  MapPinLine,
  MapTrifold,
  Textbox,
  WarningOctagon,
} from '@phosphor-icons/react'
import React from 'react'

import { cn } from 'ui'
import BadgeV2 from '../../components/ui/badge-v2'
import { DetailsInfoList } from '../review-center/review-layout-details-components'
import Alert from '../../components/ui/alert'
import Button from '../../components/ui/button'

export function DetailSectionTitle({
  title,
  icon,
  className,
  rightItem,
  containerClassName,
}) {
  return (
    <span className={cn('tw-flex tw-justify-between', containerClassName)}>
      <span
        className={cn(
          'tw-flex tw-items-center tw-text-base tw-font-bold tw-text-black',
          className,
        )}
      >
        {icon}
        {title}
      </span>

      {rightItem}
    </span>
  )
}

function BankDetailValue({ value }) {
  return <span className='tw-font-semibold tw-text-black'>{value}</span>
}

export const VENDOR_BANK_ACCOUNT_STATUS = {
  MISSING_DETAILS: 'missing bank details',
  IN_REVIEW: 'bank details in review',
  FAILED: 'bank details verification failed',
  WAITING_FOR_FUNDS: 'waiting for funds',
}

const BANK_BADGE_CLASSNAME = {
  pending: '!tw-bg-systemGold-20 !tw-text-systemGold-110',
  onhold: '!tw-bg-systemGold-20 !tw-text-systemGold-110',
  approved: '!tw-bg-systemGreen-20 !tw-text-systemGreen-110',
  rejected: '!tw-bg-systemRed-20 !tw-text-systemRed-110',
}

export const getBankDetailsStatus = (bankAccount) => {
  const values = { label: '', className: '' }
  if (bankAccount?.details) {
    values.label =
      bankAccount.screening_status ?? VENDOR_BANK_ACCOUNT_STATUS.FAILED
    values.className = bankAccount.screening_status
      ? BANK_BADGE_CLASSNAME[values.label]
      : BANK_BADGE_CLASSNAME.rejected
  } else if (!bankAccount?.details) {
    values.label = VENDOR_BANK_ACCOUNT_STATUS.MISSING_DETAILS
    values.className = BANK_BADGE_CLASSNAME.rejected
  }

  return values
}

export function BankBadge({ status, details }) {
  return (
    <BadgeV2
      name={getBankDetailsStatus({ screening_status: status, details }).label}
      className={
        getBankDetailsStatus({ screening_status: status, details }).className
      }
    />
  )
}

export function VendorBankDetails({
  bankInfo,
  className,
  handleAddBankAccount,
  canManageBills,
}) {
  const { details, screening_status: status } = bankInfo ?? {}

  return (
    <>
      <DetailSectionTitle
        title='Bank details'
        icon={<Bank size={20} className='tw-mr-2 tw-fill-primary' />}
        rightItem={<BankBadge status={status} details={details} />}
        containerClassName='tw-justify-start tw-gap-2 tw-mb-4'
      />

      {details ? (
        <DetailsInfoList
          className={cn('tw-p-0', className)}
          items={(bankInfo?.details ?? [])?.map(({ name: label, value }) => {
            return {
              label,
              icon: <BankDetailIcon label={label} size={24} />,
              value: <BankDetailValue value={value} />,
              className: 'tw-flex-nowrap',
              valueClassName: 'tw-text-wrap tw-text-right',
            }
          })}
        />
      ) : (
        <Alert
          className='tw-mt-5 !tw-text-wrap !tw-border-systemRed-20 !tw-bg-systemRed-20 !tw-p-4 !tw-text-xs !tw-text-text-80'
          customIcon={
            <WarningOctagon size={20} className='tw-fill-systemRed' />
          }
          iconClassName='[--icon-size:1.25rem]'
          innerClassName='tw-mt-0'
          innerTag='div'
        >
          <p className='tw-mb-0'>
            Bank details are missing for this vendor, make sure to add them in
            order to pay bills.
          </p>

          {!handleAddBankAccount ||
          typeof handleAddBankAccount !== 'function' ||
          !canManageBills ? null : (
            <Button
              color='link'
              className='tw-mt-2 !tw-px-0 !tw-py-1 !tw-text-primary'
              onClick={handleAddBankAccount}
            >
              Add Bank Details
            </Button>
          )}
        </Alert>
      )}
    </>
  )
}

function BankDetailIcon({ label, className = 'tw-fill-text-60', size = 24 }) {
  switch (label.toLowerCase()) {
    case 'beneficiary name': {
      return <Textbox size={size} className={className} />
    }
    case 'beneficiary type': {
      return <IdentificationBadge size={size} className={className} />
    }
    case 'account number':
    case 'bank name': {
      return <Bank size={size} className={className} />
    }
    case 'bic code':
    case 'swift code': {
      return <GlobeSimple size={size} className={className} />
    }
    case 'account holder':
    case 'iban': {
      return <IdentificationCard size={size} className={className} />
    }
    case 'country': {
      return <Flag size={size} className={className} />
    }
    case 'state': {
      return <MapPinLine size={size} className={className} />
    }
    case 'city': {
      return <MapTrifold size={size} className={className} />
    }
    case 'currency': {
      return <Coin size={size} className={className} />
    }
    case 'post code': {
      return <EnvelopeSimple size={size} className={className} />
    }
    case 'first line':
    case 'second line': {
      return <MapPin size={size} className={className} />
    }
    default: {
      return <Asterisk size={size} className={className} />
    }
  }
}
