import {
  Baby,
  HeartStraightBreak,
  PaperPlaneTilt,
  Star,
  Thermometer,
  TreePalm,
} from '@phosphor-icons/react'
import React from 'react'
import { Button as RAButton, TooltipTrigger } from 'react-aria-components'
import { Link } from 'react-router-dom'

import { Avatar, cn } from 'ui'
import { CONTRACT_STATUS } from '../../../helpers/enum'
import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { getFullName } from '../../../utils/get-full-name'
import { TooltipV2 } from '../../../components/VerticalLayout/tooltip-v2'
import { UncontrolledTooltip } from 'reactstrap'

export function WorkerName({ item }) {
  const fullName = getFullName(item?.contractor)

  const { hasAccess } = usePermissions()

  const hasViewPermission = hasAccess(permissions.ViewContractsDetails)

  if (!hasViewPermission) {
    return fullName
  }

  const { photo, email } = item.contractor || {}

  const nameOrEmail = fullName || item.worker_name || email

  const isPendingInvite =
    item.status?.id === CONTRACT_STATUS.PENDING_INVITE.value

  const pendingInviteText = ['Invite', !nameOrEmail ? null : `(${nameOrEmail})`]
    .filter(Boolean)
    .join(' ')

  return (
    <TooltipTrigger delay={500}>
      <RAButton>
        <Link
          className={cn(
            'tw-flex tw-gap-2 tw-text-start',
            !isPendingInvite && 'tw-text-text-100',
          )}
          to={`/contract/detail?id=${item.ref}`}
        >
          {!photo && isPendingInvite ? (
            <span className='tw-flex tw-size-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-30'>
              <PaperPlaneTilt size={20} />
            </span>
          ) : (
            <Avatar photo={photo} name={nameOrEmail} size='lg' />
          )}

          <div className='tw-flex tw-flex-col'>
            <div className='tw-flex tw-items-center tw-gap-x-2'>
              <span className={cn('tw-max-w-[20ch] tw-truncate tw-font-bold')}>
                {isPendingInvite ? pendingInviteText : nameOrEmail}
              </span>
              {item?.time_off_status && (
                <>
                  <TimeOffIndicator
                    id={'icon_status_' + item?.id}
                    timeOffStatus={item?.time_off_status}
                  />
                </>
              )}
            </div>
            <span className='tw-text-left tw-text-xs tw-font-medium tw-text-text-80'>
              {item.ref}
            </span>
          </div>
        </Link>
      </RAButton>

      {(
        isPendingInvite
          ? pendingInviteText?.length > 20
          : nameOrEmail?.length > 20
      ) ? (
        <TooltipV2 placement='top'>{nameOrEmail}</TooltipV2>
      ) : null}
    </TooltipTrigger>
  )
}

export function TimeOffIndicator({ id, timeOffStatus }) {
  return (
    <>
      <TimeOffIcon id={id} policyName={timeOffStatus?.policy_name} />
      <UncontrolledTooltip
        placement='bottom'
        target={id}
        // override bootstrap max-width
        style={{
          maxWidth: 'none',
        }}
      >
        <div className='tw-text-sm'>{timeOffStatus?.description}</div>
        <div className='tw-text-left tw-text-text-30'>
          {timeOffStatus?.policy_name}
        </div>
      </UncontrolledTooltip>
    </>
  )
}

function TimeOffIcon({ id, policyName }) {
  const className =
    'tw-rounded-full tw-bg-primary-30 tw-p-1 tw-text-primary-100 tw-size-6'
  switch (policyName) {
    case 'Sick leave':
      return <Thermometer id={id} className={className} />
    case 'Parental leave':
      return <Baby id={id} className={className} />
    case 'Religious':
      return <Star id={id} className={className} />
    case 'Bereavement':
      return <HeartStraightBreak id={id} className={className} />
    default:
      return <TreePalm id={id} className={className} />
  }
}
