import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Button from '../../components/ui/button'
import { useForm } from 'react-hook-form'
import ControlledInput from '../../components/ControlledInput'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { v4 as uuid } from 'uuid'

const tenureErrorText = 'Tenure must be a positive integer'
const timeOffAmountErrorText = 'Time off amount must be a positive integer'

const schema = yup.object().shape({
  tenure: yup
    .number()
    .integer(tenureErrorText)
    .required(tenureErrorText)
    .min(1, tenureErrorText)
    .typeError(tenureErrorText),
  timeOffAmount: yup
    .number()
    .integer(timeOffAmountErrorText)
    .required(timeOffAmountErrorText)
    .min(1, timeOffAmountErrorText)
    .typeError(timeOffAmountErrorText),
})

function AddTenureRule({
  isOpen,
  toggle,
  onSave,
  tenureRules = [],
  timeOffAmount,
  tenureRule,
}) {
  const { control, handleSubmit, setError } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tenure: tenureRule?.tenure,
      timeOffAmount: tenureRule?.amount,
    },
  })
  function submitForm(values) {
    const tenure = parseInt(values.tenure)
    const amount = parseInt(values.timeOffAmount)
    const originalTimeOffAmount = parseInt(timeOffAmount ?? 0)
    const sortedTenures = tenureRules
      .filter((rule) => !tenureRule || rule.id !== tenureRule.id)
      .sort((a, b) => a.tenure - b.tenure)

    const existingRule = sortedTenures.find((rule) => rule.tenure === tenure)
    if (existingRule) {
      setError('tenure', { message: 'Tenure rule already exists' })
      return
    }

    const tenureBefore = [...sortedTenures]
      .reverse()
      .find((rule) => rule.tenure < tenure)
    const tenureAfter = sortedTenures.find((rule) => rule.tenure > tenure)

    if (originalTimeOffAmount >= amount) {
      setError('timeOffAmount', {
        message:
          'Time off amount must be greater than the initial time off amount',
      })
      return
    }
    if (tenureBefore && tenureBefore.amount >= amount) {
      setError('timeOffAmount', {
        message:
          'Time off amount must be greater than the previous tenure rule',
      })
      return
    }
    if (tenureAfter && tenureAfter.amount <= amount) {
      setError('timeOffAmount', {
        message: 'Time off amount must be less than the next tenure rule',
      })
      return
    }

    const newItemIndex = sortedTenures.indexOf(tenureBefore) + 1 || 0
    sortedTenures.splice(newItemIndex, 0, { tenure, amount, id: uuid() })

    onSave(sortedTenures)
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <form onSubmit={handleSubmit(submitForm)}>
        <ModalHeader toggle={toggle}>
          {tenureRule ? 'Update' : 'New'} Tenure Rule
        </ModalHeader>
        <ModalBody className='tw-flex tw-flex-col tw-gap-4'>
          <ControlledInput
            control={control}
            name='tenure'
            type='number'
            label='If tenure is more than'
            postFix='Year(s)'
          />
          <ControlledInput
            control={control}
            name='timeOffAmount'
            type='number'
            label='Time off amount'
            postFix='Working days'
          />
        </ModalBody>

        <ModalFooter>
          <Button color='secondary' onClick={toggle} outline>
            Cancel
          </Button>
          <Button color='primary' type='submit'>
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default AddTenureRule
