import React from 'react'
import {
  CaretUp,
  CaretDown,
  ClockCountdown,
  TrashSimple,
} from '@phosphor-icons/react'
import { Card, Input } from 'reactstrap'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { cn } from 'ui'
import { Controller, useFormContext } from 'react-hook-form'
import { delayTypeOfDays } from './constants'

export function Delay(props) {
  const {
    id,
    index,
    stepsField: { remove },
  } = props
  const { control, watch, setValue } = useFormContext()
  const focused = watch('focusedNode') === id

  return (
    <div
      className={cn(
        'tw-rounded-md',
        focused ? 'tw-border-2 tw-border-primary-60' : undefined,
      )}
    >
      <Card className='!tw-mb-0 tw-flex !tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4'>
        <div className='tw-rounded tw-bg-primary-20 tw-p-2'>
          <ClockCountdown size={25} className='tw-text-primary' />
        </div>
        <div className='tw-flex tw-flex-1 tw-flex-col'>
          <span className='tw-font-medium'>Wait For</span>
          <span>Select delay before executing the next action</span>
        </div>

        <button
          className='tw-rounded tw-bg-secondary-20 tw-p-2'
          onClick={() => remove(index)}
        >
          <TrashSimple size={25} className='tw-text-secondary' />
        </button>
        <button
          onClick={() => setValue('focusedNode', focused ? null : id)}
          className='tw-rounded tw-bg-primary-20 tw-p-2'
        >
          {focused ? (
            <CaretUp size={25} className='tw-text-primary' />
          ) : (
            <CaretDown size={25} className='tw-text-primary' />
          )}
        </button>
      </Card>

      {focused ? (
        <div className='tw-flex tw-flex-row tw-gap-3.5 tw-p-3.5'>
          <Controller
            render={({ field: { value, onChange, onBlur } }) => (
              <Input
                id='input'
                value={value}
                onChange={onChange}
                className='!tw-h-auto tw-flex-1'
                onBlur={onBlur}
                placeholder='Number of days'
              />
            )}
            name={`steps.${index}.numberOfDays`}
          />

          <Controller
            control={control}
            name={`steps.${index}.typeOfDays`}
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                value={value}
                defaultValue={delayTypeOfDays[0]}
                options={delayTypeOfDays}
                placeholder='Type of days'
                onChange={onChange}
                wrapperClassName='tw-flex-1'
              />
            )}
          />
        </div>
      ) : null}
    </div>
  )
}
