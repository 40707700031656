import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import toastr from 'toastr'
import * as yup from 'yup'

import { getVendorFormStepId } from '.'
import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import { useFetch } from '../../../../helpers/hooks'
import {
  createVendor,
  updateVendor,
} from '../../../../services/api-bill-payments'
import {
  mapCountryToOption,
  mapListToOption,
} from '../../../../utils/map-to-option'
import { useCreateVendorFlowContext } from './create-vendor-context'
import { FormSectionHr } from '../../../Contract/CreateContract/components/form-section-title'

export const vendorDetailFields = {
  name: 'name',
  category_id: 'category_id',
  address: 'address',
  city: 'city',
  country_id: 'country_id',
  state_id: 'state_id',
}

function pick(obj, keys) {
  return keys.reduce((acc, key) => {
    acc[key] = obj[key]
    return acc
  }, {})
}

function getChangedValues(object1, object2, keys) {
  return keys.reduce((acc, key) => {
    if (object1[key] !== object2[key]) {
      acc[key] = object2[key]
    }
    return acc
  }, {})
}

export const vendorDetailSchema = yup.object().shape({
  [vendorDetailFields.name]: yup
    .string()
    .required('Vendor name is required')
    .label('Vendor name'),
  [vendorDetailFields.category_id]: yup.string().label('Vendor Category'),
  [vendorDetailFields.country_id]: yup.string().label('Country'),
  [vendorDetailFields.state_id]: yup.string().label('State'),
  [vendorDetailFields.city]: yup.string().label('City'),
  [vendorDetailFields.address]: yup.string().label('Address'),
})

export default function CreateVendorStep1({
  step,
  goToNextStep,
  setLoading,
  setDisabledNext,
}) {
  const { vendorFormData, setVendorFormData } = useCreateVendorFlowContext()

  const { handleSubmit, control } = useForm({
    defaultValues: { ...vendorFormData },
    resolver: yupResolver(vendorDetailSchema),
  })

  function updatedFormVendorDetails(data) {
    setVendorFormData({
      ...data,
      category_id: data?.category?.id,
      country_id: data?.country?.id,
      state_id: data?.state?.id,
      // createdVendor is used to track the changes made to the vendor
      createdVendor: data,
    })
  }
  const { startFetch: createTheVendor } = useFetch({
    action: createVendor,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while creating the vendor')
      } else {
        updatedFormVendorDetails(data)
        goToNextStep()
      }

      setLoading(false)
      setDisabledNext(false)
    },
    onError: (err) => {
      toastr.error(err)
      setLoading(false)
      setDisabledNext(false)
    },
  })

  const { startFetch: _updateVendor } = useFetch({
    action: updateVendor,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while updating the vendor')
      } else {
        updatedFormVendorDetails(data)
        goToNextStep()
      }

      setLoading(false)
      setDisabledNext(false)
    },
    onError: (err) => {
      toastr.error(err)
      setLoading(false)
      setDisabledNext(false)
    },
  })

  function onSubmit(values) {
    setLoading(true)
    setDisabledNext(true)

    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([, value]) => !!value),
    )

    const shouldUpdate = vendorFormData?.id

    if (shouldUpdate) {
      const vendorFieldKeys = Object.values(vendorDetailFields)
      const filteredVendorValues = pick(filteredValues, vendorFieldKeys)

      const filteredCreatedVendorValues = Object.fromEntries(
        Object.entries(vendorFormData?.createdVendor)
          .map(([key, value]) => {
            switch (key) {
              case 'category': {
                return [vendorDetailFields.category_id, String(value?.id)]
              }
              case 'country': {
                return [vendorDetailFields.country_id, String(value?.id)]
              }
              case 'state': {
                return [vendorDetailFields.state_id, String(value?.id)]
              }
              default: {
                return [key, value]
              }
            }
          })
          .filter(([, value]) => !!value),
      )

      const createdVendorValues = pick(
        filteredCreatedVendorValues,
        vendorFieldKeys,
      )

      const changedValues = getChangedValues(
        createdVendorValues,
        filteredVendorValues,
        vendorFieldKeys,
      )

      const haveChangedValues = Object.keys(changedValues).length > 0

      if (haveChangedValues) {
        _updateVendor({
          id: vendorFormData?.id,
          ...changedValues,
          name: filteredVendorValues?.name,
          city: filteredVendorValues?.city || undefined,
          address: filteredVendorValues?.address || undefined,
          country_id: filteredVendorValues?.country_id || undefined,
          state_id: filteredVendorValues?.state_id || undefined,
        })
      } else {
        setLoading(false)
        setDisabledNext(false)
        goToNextStep()
      }
    } else {
      createTheVendor(filteredValues)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={getVendorFormStepId(step)}>
      <VendorDetailForm control={control} className='tw-space-y-4' />
    </form>
  )
}

export function VendorDetailForm({ control, className }) {
  const { countries = [], integration_categories: integrationCategories = [] } =
    useSelector((state) => state?.Layout?.staticData ?? {})

  const countryId = useWatch({ control, name: vendorDetailFields.country_id })
  const selectedCountry = countries?.find((c) => c.id === countryId)

  return (
    <div className={className}>
      <h3 className='tw-text-xl tw-font-semibold'>Details</h3>
      <ControlledInput
        control={control}
        name={vendorDetailFields.name}
        id={vendorDetailFields.name}
        label='Name'
        placeholder='Name'
      />

      <ControlledSelect
        control={control}
        name={vendorDetailFields.category_id}
        inputId={vendorDetailFields.category_id}
        label='Category'
        placeholder='Category'
        options={integrationCategories.map((c) => mapListToOption(c))}
      />

      <FormSectionHr className='!tw-mb-4 !tw-mt-6' />

      <h3 className='tw-text-xl tw-font-semibold'>Address</h3>
      <ControlledSelect
        control={control}
        name={vendorDetailFields.country_id}
        inputId={vendorDetailFields.country_id}
        label='Country'
        placeholder='Country'
        options={countries?.map((c) => mapCountryToOption(c))}
      />

      {!selectedCountry || selectedCountry?.states?.length <= 0 ? null : (
        <ControlledSelect
          control={control}
          name={vendorDetailFields.state_id}
          inputId={vendorDetailFields.state_id}
          label='State'
          placeholder='State'
          options={selectedCountry?.states?.map((c) => mapListToOption(c))}
        />
      )}

      <ControlledInput
        control={control}
        name={vendorDetailFields.city}
        id={vendorDetailFields.city}
        label='City'
        placeholder='City'
      />

      <ControlledInput
        control={control}
        name={vendorDetailFields.address}
        id={vendorDetailFields.address}
        label='Address'
        placeholder='Address'
      />
    </div>
  )
}
