import isNill from '../is-nill'

export function getFloat(value) {
  if (typeof value === 'string' || typeof value === 'number') {
    value = parseFloat(value)
  }

  return value
}

export function formatDays(days) {
  if (isNill(days)) {
    return null
  }

  const _days = getFloat(days)

  return `${_days} day${Number(_days) === 1 ? '' : 's'}`
}

export function formatHours(hours) {
  if (isNill(hours)) {
    return null
  }

  const _hours = getFloat(hours)

  return `${_hours} hour${Number(_hours) === 1 ? '' : 's'}`
}
