import cx from 'classnames'
import qs from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { animated, useSpring } from 'react-spring'
import { Container, NavItem, TabPane } from 'reactstrap'
import toastr from 'toastr'
import { Avatar } from 'ui'

import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { StyledH5, StyledH6 } from '../../../components/Typo'
import Head from '../../../components/head'
import { PageNav } from '../../../components/page-nav'
import BadgeV2 from '../../../components/ui/badge-v2'
import Loader from '../../../components/ui/loader'
import TabContent from '../../../components/ui/tabs'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import {
  BE_CONTRACT_CATEGORY,
  CONTRACT_STATUS,
  userTypes,
} from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { getContractDetail, getInsurancePlans } from '../../../services/api'
import { updateDeepLink } from '../../../store/auth/register/actions'
import { updateContract } from '../../../store/contract/actions'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { getErrorMessage } from '../../../utils/get-errors'
import { getFullName } from '../../../utils/get-full-name'
import { getAgeBracket } from '../../../utils/insurance-age-bracket'
import Payslips from '../../Fulltime/Payslips'
import ContractDetail from '../ContractDetail/index'
import { NeedSignModal } from '../components/need-sign-modal'
import Adjustments from './Adjustments'
import { Allowance } from './Allowance'
import ContractHistory from './ContractHistory'
import Documents from './Documents'
import Equipments from './Equipments'
import Expenses from './Expenses'
import PaymentHistory from './Payments'
import Settings from './Settings'
import DeDocumentsTab from './de-documents-tab'
import { Invoices } from './invoices-tab'
import { MilestoneSideMenu, Milestones } from './milestones-tab'
import SubmittedWork from './submitted-work'
import { TimesOffTab } from './times-off-tab'
import { NewBadge } from '../../../components/ui/badge-new'
import { TimeOffIndicator } from '../ContractList/worker-name'

const ContractPage = (props) => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(
    props.location.hash === '#work' ? '8' : '5',
  )

  const searchParams = new URLSearchParams(props.location.search)
  const id = searchParams.get('id')
  const tab = searchParams.get('tab')

  if (!id) {
    history.push('/contracts')
  }

  const [data, setData] = useState({})

  const [needSign, setNeedSign] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showEndedModal, setEndedModal] = useState(false)
  const [clientSignature, setClientSignature] = useState(null)
  const [contractorSignature, setContractorSignature] = useState(null)
  const [showSwitchPopup, setShowSwitchPopup] = useState(false)
  const [fetchingContractDetailsData, setFetchingContractDetailsData] =
    useState(false)
  const isMobile = useResize()
  const isProvider = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'provider',
  )
  const userIsPartner = useSelector(
    (state) => state.userProfile?.userProfile?.is_partner,
  )

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const contractorType = userProfile?.contractor_type

  const isDeEmployee = contractorType === BE_CONTRACT_CATEGORY.DIRECT_EMPLOYEE

  const dispatch = useDispatch()
  const { hasAccess } = usePermissions()

  const updateTabLink = (tab) => {
    let query = qs.parse(props.location.search)
    const prevTab = query?.tab

    if ((!tab && !prevTab) || tab === prevTab) {
      return
    }

    if (tab) {
      query = { ...query, tab }
    }

    const searchString = qs.stringify(query)

    history.push({ search: searchString })
  }

  const switchTabs = (tab) => {
    const search = qs.stringify({ ...qs.parse(props.location.search), tab })
    history.push(`${props.location.pathname}?${search}`)
  }

  useEffect(() => {
    switch (tab) {
      case 'payments':
        setActiveTab('6')
        break
      case 'documents':
        setActiveTab('7')
        break
      case 'works':
        if (data.type === CONTRACT_TYPES.MILESTONES) {
          switchTabs(CONTRACT_TYPES.MILESTONES.toLowerCase())
          break
        }
        setActiveTab('8')
        break
      case 'expenses':
        setActiveTab('9')
        break
      case 'timeOff':
        setActiveTab('10')
        break
      case 'settings':
        setActiveTab('11')
        break
      case 'payslips':
        setActiveTab('12')
        break
      case 'equipment':
        setActiveTab('13')
        break
      case 'allowance':
        setActiveTab('14')
        break
      case 'history':
        setActiveTab('15')
        break
      case 'adjustments':
        setActiveTab('16')
        break
      case 'milestones':
        if (data.type === CONTRACT_TYPES.FULL_TIME) {
          switchTabs('works')
          break
        }
        setActiveTab('17')
        break
      default:
        setActiveTab('5')
        break
    }
  }, [tab, data.type])

  useEffect(() => {
    let eventName = ''
    switch (activeTab) {
      case '5':
        updateTabLink(null)
        eventName = 'Viewed contract details'
        break
      case '6':
        updateTabLink('payments')
        eventName = `Viewed payments tab`
        break
      case '7':
        updateTabLink('documents')
        eventName = 'Viewed document tab'
        break
      case '8':
        updateTabLink('works')
        eventName = 'Viewed work submission'
        break
      case '9':
        updateTabLink('expenses')
        eventName = 'Viewed Expenses'
        break
      case '10':
        updateTabLink('timeOff')
        eventName = 'Viewed timeOff'
        break
      case '11':
        updateTabLink('settings')
        eventName = 'Viewed settings'
        break
      case '12':
        updateTabLink('payslips')
        eventName = 'Viewed payslips'
        break
      case '13':
        updateTabLink('equipment')
        eventName = 'Viewed equipments'
        break
      case '14':
        updateTabLink('allowance')
        eventName = 'Viewed allowance'
        break
      case '15':
        updateTabLink('history')
        eventName = 'Viewed history'
        break
      case '16':
        updateTabLink('adjustments')
        eventName = 'Viewed adjustments'
        break
      case '17':
        updateTabLink('milestones')
        eventName = 'Viewed milestones'
        break
    }
    if (data) {
      window.analytics.track(eventName, {
        contract_id: data.ref,
        contract_type: data.type,
        contract_status: data?.status?.name,
      })
    }
  }, [activeTab, data])

  const { startFetch: getContractDetails, isLoading: contractDetailsLoading } =
    useFetch(
      {
        autoFetch: true,
        body: { id },
        action: getContractDetail,
        onComplete: (contract) => {
          window.analytics.track('Viewed a contract', {
            contract_id: contract.ref,
            contract_status: contract.status?.name,
            contract_type: contract.type,
          })
          if (contract?.switch_required) {
            setShowSwitchPopup(true)
          } else {
            setData(contract)
            dispatch(updateContract(contract))
            if (contract?.client) {
              setClientSignature(
                contract?.signatures?.find(
                  (e) => e?.user_id === contract.client?.id,
                ),
              )
            }
            if (contract?.contractor) {
              setContractorSignature(
                contract?.signatures?.find(
                  (e) => e?.user_id === contract?.contractor?.id,
                ),
              )
            }
          }
          setFetchingContractDetailsData(false)
        },
        onError: (err) => {
          setFetchingContractDetailsData(false)
          if (err.message === 'Unauthorised') {
            props.history.push('/no-access')
          }
          toastr.error(getErrorMessage(err))
        },
      },
      [id],
    )

  function updateContractDetail(withLoading = true) {
    if (!id) {
      history.push('/contracts')
      return
    }
    setFetchingContractDetailsData(true)
    getContractDetails({ id }, withLoading)
  }

  const contractUser =
    user?.type === userTypes.CONTRACTOR
      ? data.client
      : data?.employee || data.contractor

  const contractUserHasBirthDateAndCountry =
    !!contractUser?.birth_date && contractUser?.country?.id
  const insurancePlans = useFetch(
    {
      action: getInsurancePlans,
      autoFetch: contractUserHasBirthDateAndCountry,
      body: {
        ages: getAgeBracket(contractUser?.birth_date),
        country_id: contractUser?.country?.id,
      },
    },
    [contractUser?.birth_date, contractUser?.country?.id],
  )

  const showInsuranceAllowanceTab =
    [CONTRACT_TYPES.FIXED, CONTRACT_TYPES.PAY_AS_YOU_GO].includes(data?.type) &&
    user?.type === userTypes.COMPANY &&
    data?.status?.id === CONTRACT_STATUS.ONGOING.value &&
    !data?.employee &&
    !insurancePlans.data?.country_not_supported &&
    hasAccess(permissions.viewExpenses)

  const showSettingsTab =
    user?.type === userTypes.COMPANY &&
    hasAccess(permissions.viewContractSettings)

  const showAvatar = useMemo(() => activeTab !== '5', [activeTab])

  const [avatarStyles] = useSpring(() => {
    return {
      opacity: showAvatar || isMobile ? 1 : 0,
      scale: showAvatar || isMobile ? 1 : 0.3,
      x: 0,
      config: { duration: 200 },
    }
  }, [showAvatar])

  const [contractRefStyles] = useSpring(() => {
    return {
      x: showAvatar || isMobile ? '0rem' : '-5.6rem',
      config: { duration: 200 },
    }
  }, [showAvatar])

  const isDirectEmployeeContract = data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
  const contractsWithTimeOffEnabled = [
    CONTRACT_TYPES.FIXED,
    CONTRACT_TYPES.FULL_TIME,
    CONTRACT_TYPES.DIRECT_EMPLOYEE,
  ]

  if (FEATURE_FLAGS.PAYG_TIMEOFF) {
    contractsWithTimeOffEnabled.push(CONTRACT_TYPES.PAY_AS_YOU_GO)
  }

  const showTimeOffTab =
    contractsWithTimeOffEnabled.includes(data?.type) &&
    [
      CONTRACT_STATUS.ONGOING.value,
      CONTRACT_STATUS.TERMINATED.value,
      CONTRACT_STATUS.PENDING_INVITE.value,
      CONTRACT_STATUS.PENDING_CLIENT_SIGNATURE.value,
      CONTRACT_STATUS.PENDING_CONTRACTOR_SIGNATURE.value,
    ].includes(data?.status?.id) &&
    (hasAccess(permissions.viewTimeOffs) ||
      data?.can_approve_timeoff ||
      user?.type === userTypes.CONTRACTOR)

  const showPaySlipsTab =
    [CONTRACT_TYPES.FULL_TIME, CONTRACT_TYPES.DIRECT_EMPLOYEE].includes(
      data?.type,
    ) &&
    [CONTRACT_STATUS.ONGOING.value, CONTRACT_STATUS.TERMINATED.value].includes(
      data?.status?.id,
    )

  const showEquipmentTab =
    data?.type !== CONTRACT_TYPES.FULL_TIME &&
    [
      CONTRACT_STATUS.TERMINATED.value,
      CONTRACT_STATUS.ENDED.value,
      CONTRACT_STATUS.ONGOING.value,
    ].includes(data?.status?.id) &&
    hasAccess(permissions.viewEquipments)

  const isPending = [
    CONTRACT_STATUS.PENDING_INVITE.value,
    CONTRACT_STATUS.PENDING_CONTRACTOR_SIGNATURE.value,
    CONTRACT_STATUS.PENDING_CLIENT_SIGNATURE.value,
  ].includes(data?.status?.id)

  const showPaymentsTab =
    (hasAccess(permissions.ViewContractsPayments) ||
      user?.type === userTypes.CONTRACTOR) &&
    !isDeEmployee &&
    (isDirectEmployeeContract ? !isPending : true)
  const showDePaymentsTab =
    isDeEmployee && (isDirectEmployeeContract ? !isPending : true)
  const showAdjustmentsTab =
    user?.type === userTypes.COMPANY && data?.type === CONTRACT_TYPES.FULL_TIME

  const showInvoicesTab =
    CONTRACT_TYPES.FULL_TIME === data.type || userIsPartner
  const showMilestonesTab = CONTRACT_TYPES.MILESTONES === data.type
  return (
    <div className='page-content'>
      <Head
        title={['Contract', data?.ref ? `#${data?.ref}` : null]
          .filter(Boolean)
          .join(' ')}
      />

      <div
        style={{ minHeight: 72 }}
        className='d-flex align-items-center gap-16 mb-3'
      >
        {!data?.id ? null : contractUser && user?.type === userTypes.COMPANY ? (
          <>
            <animated.div
              style={{ ...avatarStyles, transformOrigin: 'left center' }}
            >
              <Avatar
                label={
                  user?.type === userTypes.CONTRACTOR ? 'Client' : 'Contractor'
                }
                size='special'
                flag={contractUser?.flag}
                name={contractUser?.first_name || contractUser?.email}
                photo={contractUser?.photo}
              />
            </animated.div>
            <animated.div style={{ ...contractRefStyles }}>
              <h3 className='font-size-24 rp-font-bold tw-flex tw-items-center tw-gap-2'>
                <span>
                  {contractUser?.first_name} {contractUser?.last_name}
                </span>
                {data.time_off_status && (
                  <TimeOffIndicator
                    id={'icon_status_' + data?.id}
                    timeOffStatus={data?.time_off_status}
                  />
                )}
              </h3>
              <h4 className='font-size-16 rp-font-bold mb-0'>
                Contract{' '}
                <span className='text-muted font-weight-light'>
                  #{data?.ref}
                </span>
              </h4>
            </animated.div>
          </>
        ) : (
          <>
            <StyledH5 min='22px' mid='28px' max='32px' className='mb-0'>
              Contract
            </StyledH5>
            <StyledH6
              min='22px'
              mid='28px'
              max='32px'
              className='text-secondary font-weight-normal mb-0'
            >
              #{data?.ref}
            </StyledH6>
          </>
        )}
      </div>

      <Container fluid className='p-0 m-0'>
        {showSwitchPopup ? (
          <ConfirmationModal
            isOpen={showSwitchPopup}
            toggle={() => setShowSwitchPopup(false)}
            title='Switch Account'
            onConfirm={() => {
              const stateFrom = history.location
              if (stateFrom) {
                dispatch(
                  updateDeepLink(`${stateFrom?.pathname}${stateFrom?.search}`),
                )
              }
              history.push('/switch')
            }}
            caption='Switch'
            message={`You need to switch to ${
              user?.type === userTypes.COMPANY ? 'Contractor' : 'Client'
            } account to see this contract`}
          />
        ) : (
          <div className='tw-mb-4 tw-rounded tw-bg-white'>
            <PageNav className='tw-rounded'>
              <NavItem>
                <PageNav.Link
                  tag='button'
                  type='button'
                  isActive={activeTab === '5'}
                  onClick={() => {
                    setActiveTab('5')
                    switchTabs()
                  }}
                >
                  Contract Details
                </PageNav.Link>
              </NavItem>
              {!showSettingsTab ? null : (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '11'}
                    onClick={() => {
                      setActiveTab('11')
                    }}
                  >
                    Settings
                  </PageNav.Link>
                </NavItem>
              )}
              {(showPaymentsTab || showDePaymentsTab) && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '6'}
                    onClick={() => {
                      setActiveTab('6')
                    }}
                  >
                    Payments
                  </PageNav.Link>
                </NavItem>
              )}
              {showAdjustmentsTab && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '16'}
                    onClick={() => {
                      setActiveTab('16')
                    }}
                  >
                    Adjustments
                  </PageNav.Link>
                </NavItem>
              )}
              {(hasAccess(permissions.ViewContractsDocuments) ||
                user?.type === userTypes.CONTRACTOR) &&
                data?.type !== CONTRACT_TYPES.FULL_TIME &&
                !(
                  user?.type === userTypes.COMPANY && data?.status?.id === 2
                ) && (
                  <NavItem>
                    <PageNav.Link
                      tag='button'
                      type='button'
                      isActive={activeTab === '7'}
                      onClick={() => {
                        setActiveTab('7')
                      }}
                    >
                      Documents
                    </PageNav.Link>
                  </NavItem>
                )}
              {showMilestonesTab && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '17'}
                    onClick={() => {
                      setActiveTab('17')
                    }}
                  >
                    {CONTRACT_TYPES.MILESTONES}
                  </PageNav.Link>
                </NavItem>
              )}
              {showInvoicesTab && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '8'}
                    onClick={() => {
                      setActiveTab('8')
                    }}
                  >
                    Invoices
                  </PageNav.Link>
                </NavItem>
              )}
              {data.type === CONTRACT_TYPES.PAY_AS_YOU_GO && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '8'}
                    onClick={() => {
                      setActiveTab('8')
                    }}
                  >
                    Submitted Work
                  </PageNav.Link>
                </NavItem>
              )}
              {[4, 5, 6, 7].includes(data?.status?.id) &&
                !isProvider &&
                (hasAccess(permissions.viewExpenses) ||
                  user?.type === userTypes.CONTRACTOR) && (
                  <NavItem>
                    <PageNav.Link
                      tag='button'
                      type='button'
                      isActive={activeTab === '9'}
                      onClick={() => {
                        setActiveTab('9')
                      }}
                    >
                      Expenses
                    </PageNav.Link>
                  </NavItem>
                )}
              {showTimeOffTab && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '10'}
                    onClick={() => {
                      setActiveTab('10')
                    }}
                  >
                    Time off <NewBadge className='tw-ml-1' />
                  </PageNav.Link>
                </NavItem>
              )}
              {showPaySlipsTab && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '12'}
                    onClick={() => {
                      setActiveTab('12')
                    }}
                  >
                    Payslips
                  </PageNav.Link>
                </NavItem>
              )}
              {showEquipmentTab && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '13'}
                    onClick={() => {
                      setActiveTab('13')
                    }}
                  >
                    Equipment
                  </PageNav.Link>
                </NavItem>
              )}
              {showInsuranceAllowanceTab && (
                <NavItem>
                  <PageNav.Link
                    tag='button'
                    type='button'
                    isActive={activeTab === '14'}
                    onClick={() => {
                      setActiveTab('14')
                    }}
                  >
                    Allowance
                  </PageNav.Link>
                </NavItem>
              )}
              {user?.type === userTypes.COMPANY &&
                FEATURE_FLAGS.REMOTEPASS_HISTORY && (
                  <NavItem>
                    <PageNav.Link
                      tag='button'
                      type='button'
                      isActive={activeTab === '15'}
                      onClick={() => {
                        setActiveTab('15')
                      }}
                    >
                      History
                    </PageNav.Link>
                  </NavItem>
                )}
            </PageNav>
          </div>
        )}

        {contractDetailsLoading && ['10', '12'].includes(activeTab) ? (
          <Loader minHeight='30rem' />
        ) : null}

        <TabContent activeTab={activeTab} className='p-0'>
          <TabPane tabId='5' className='p-0 m-0'>
            <ContractDetail
              history={props.history}
              id={id}
              data={data}
              updateContractDetail={updateContractDetail}
              fetchingContractDetailsData={fetchingContractDetailsData}
              getContractDetailsLoading={contractDetailsLoading}
              onShowTerminatedModal={() => {
                setShowConfirmationModal(true)
              }}
              onShowEndedModal={() => {
                setEndedModal(true)
              }}
            />
          </TabPane>
          <TabPane tabId='9' className='p-0'>
            <Expenses contractLoading={contractDetailsLoading} />
          </TabPane>
          <TabPane tabId='10' className='p-0'>
            {!showTimeOffTab ? null : <TimesOffTab contract={data} />}
          </TabPane>
          {!showSettingsTab ? null : (
            <TabPane tabId='11' className='p-0'>
              <Settings
                onUpdate={updateContractDetail}
                contractLoading={contractDetailsLoading}
              />
            </TabPane>
          )}
          {!showPaySlipsTab ? null : (
            <TabPane tabId='12' className='p-0'>
              <Payslips contractData={data} />
            </TabPane>
          )}
          {(showPaymentsTab || showDePaymentsTab) && (
            <TabPane tabId='6' className='p-0'>
              <PaymentHistory
                contract={data}
                contractLoading={contractDetailsLoading}
              />
            </TabPane>
          )}
          {showAdjustmentsTab && (
            <TabPane tabId='16' className='p-0'>
              <Adjustments
                contract={data}
                contractLoading={contractDetailsLoading}
              />
            </TabPane>
          )}
          {!(user?.type === userTypes.COMPANY && data?.status?.id === 2) && (
            <TabPane tabId='7' className='p-0'>
              {isDirectEmployeeContract ? (
                <DeDocumentsTab
                  contract={data}
                  onUpdate={updateContractDetail}
                  contractLoading={contractDetailsLoading}
                />
              ) : (
                <Documents
                  docs={data.documents}
                  isContract
                  contractId={data?.id}
                  loading={contractDetailsLoading}
                  onUpdate={updateContractDetail}
                />
              )}
            </TabPane>
          )}
          {data?.type === CONTRACT_TYPES.PAY_AS_YOU_GO && (
            <TabPane tabId='8' className='p-0'>
              <SubmittedWork
                updateContractDetail={updateContractDetail}
                contractDetails={data}
                setShowConfirmationModal={setShowConfirmationModal}
                setEndedModal={setEndedModal}
                setNeedSign={setNeedSign}
              />
            </TabPane>
          )}
          {showMilestonesTab && (
            <TabPane tabId='17' className='p-0 m-0'>
              <Milestones
                contract={data}
                updateContract={updateContractDetail}
                setNeedSign={setNeedSign}
                setShowConfirmationModal={setShowConfirmationModal}
                setEndedModal={setEndedModal}
              />
            </TabPane>
          )}
          {showInvoicesTab && (
            <TabPane tabId='8' className='p-0 m-0'>
              <Invoices
                contract={data}
                updateContract={updateContractDetail}
                setNeedSign={setNeedSign}
                setShowConfirmationModal={setShowConfirmationModal}
                setEndedModal={setEndedModal}
              />
            </TabPane>
          )}
          {showEquipmentTab && (
            <TabPane tabId='13' className='p-0'>
              <Equipments
                loadingData={contractDetailsLoading}
                contractId={data?.id}
                currency={data?.currency}
              />
            </TabPane>
          )}
          {showInsuranceAllowanceTab ? (
            <TabPane tabId='14' className='p-0'>
              <Allowance
                contractUser={contractUser}
                userInfo={user}
                contract={data}
                showOnboardingModal={activeTab === '14'}
                insurancePlans={insurancePlans}
                contractUserHasBirthDateAndCountry={
                  contractUserHasBirthDateAndCountry
                }
              ></Allowance>
            </TabPane>
          ) : null}
          {user?.type === userTypes.COMPANY &&
          FEATURE_FLAGS.REMOTEPASS_HISTORY ? (
            <TabPane tabId='15' className='p-0'>
              <ContractHistory
                contract={data}
                contractLoading={contractDetailsLoading}
              />
            </TabPane>
          ) : null}
        </TabContent>
        <ConfirmationModal
          toggle={() => setShowConfirmationModal(false)}
          isOpen={showConfirmationModal}
          title='Contract Terminated'
          message="You can't perform this action, this contract is terminated"
          onConfirm={() => {
            setShowConfirmationModal(false)
          }}
        />
        <ConfirmationModal
          toggle={() => setEndedModal(false)}
          isOpen={showEndedModal}
          title='Contract Ended'
          message="You can't perform this action, this contract is ended"
          onConfirm={() => {
            setEndedModal(false)
          }}
        />
        <NeedSignModal
          isOpen={needSign}
          toggle={() => setNeedSign(false)}
          clientSignature={clientSignature}
          contractorSignature={contractorSignature}
        />
      </Container>
    </div>
  )
}

export function getPaymentStatusColor(status) {
  switch (status) {
    case 'Paid':
    case 'Approved': {
      return 'success'
    }

    case 'Unpaid':
    case 'Declined': {
      return 'danger'
    }

    case 'Processing':
    case 'Pending approval': {
      return 'warning'
    }

    default: {
      return 'primary'
    }
  }
}

export function PaymentStatusBadgeV2({ status, paymentStatus }) {
  const _status = paymentStatus === 'Unpaid' ? status : paymentStatus

  return <BadgeV2 status={getPaymentStatusColor(_status)}>{_status}</BadgeV2>
}

export function MilestoneNameCell({ milestone, className }) {
  return (
    <div className={cx('tw-flex tw-flex-col tw-gap-y-1.5', className)}>
      <span
        className='tw-text-sm tw-font-medium tw-text-black'
        style={{ whiteSpace: 'pre-line' }}
      >
        {milestone?.details}
      </span>

      <SubmittedByOn
        by={getFullName(milestone.created_by)}
        on={milestone.submitted_at}
      />

      {milestone?.status?.id === 4 && milestone?.reason && (
        <span className='tw-text-xs tw-text-text-50'>
          Reason for decline:{' '}
          <span className='tw-font-semibold tw-text-text-100'>
            {milestone?.reason}
          </span>
        </span>
      )}
    </div>
  )
}

export function SubmittedByOn({ by, on, className }) {
  return (
    <span className={cx('tw-text-xs tw-text-text-60', className)}>
      Submitted by{' '}
      <span className='tw-font-semibold tw-text-text-100'>{by}</span> on{' '}
      <span className='tw-font-semibold tw-text-text-100'>{on}</span>
    </span>
  )
}

export function MilestoneCard({
  milestone,
  updateContractDetail,
  onSubmit,
  isAdmin,
  data,
}) {
  const formatter = getCurrencyFormatter(data?.currency?.code)

  return (
    <tr>
      <td className='!tw-px-6'>
        <MilestoneNameCell milestone={milestone} />
      </td>
      <td className='!tw-px-6 tw-text-sm tw-text-black'>
        {formatter.format(milestone.amount)}
      </td>
      <td className='!tw-px-6'>
        <PaymentStatusBadgeV2
          status={milestone.status?.name}
          paymentStatus={milestone.payment_status}
        />
      </td>

      <td className='!tw-px-6'>
        <MilestoneSideMenu
          contract={data}
          milestone={milestone}
          isAdmin={isAdmin}
          onSubmit={onSubmit}
          onUpdate={updateContractDetail}
        />
      </td>
    </tr>
  )
}

export default ContractPage
