import {
  AirplaneTakeoff,
  ArchiveBox,
  ArrowsLeftRight,
  Asterisk,
  Briefcase,
  CalendarCheck,
  Car,
  CarProfile,
  ChartLineUp,
  GasPump,
  GlobeSimple,
  Hamburger,
  Hammer,
  MoonStars,
  Percent,
  Phone,
  Popcorn,
  ShootingStar,
  SquaresFour,
  Taxi,
  Train,
} from '@phosphor-icons/react'
import React from 'react'

export default function BillCategoryIcon({ category }) {
  const className = 'tw-size-5'
  switch (category) {
    case 1:
      return <Hammer className={className} />
    case 2:
      return <ShootingStar className={className} />
    case 3:
      return <ChartLineUp className={className} />
    case 4:
      return <Percent className={className} />
    case 5:
      return <AirplaneTakeoff className={className} />
    case 6:
      return <Car className={className} />
    case 7:
      return <CalendarCheck className={className} />
    case 8:
      return <Popcorn className={className} />
    case 9:
      return <GasPump className={className} />
    case 10:
      return <Train className={className} />
    case 11:
      return <GlobeSimple className={className} />
    case 12:
      return <MoonStars className={className} />
    case 13:
      return <Hamburger className={className} />
    case 14:
      return <CarProfile className={className} />
    case 15:
      return <Phone className={className} />
    case 16:
      return <Briefcase className={className} />
    case 17:
      return <ArchiveBox className={className} />
    case 18:
      return <Taxi className={className} />
    case 19:
      return <SquaresFour className={className} />
    case 20:
      return <ArrowsLeftRight className={className} />
    default:
      return <Asterisk className={className} />
  }
}
