import Toggle from '../../../../../components/Forms/Toggle/Toggle'
import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { WarningOctagon } from '@phosphor-icons/react'
import { useFetch } from '../../../../../helpers/hooks'
import { updateAutomation } from '../../../../../services/api-automations'

export function AutomationStatusColumn({ rowData }) {
  const [open, setOpen] = useState()
  const toggle = () => setOpen((prv) => !prv)
  const [published, setPublished] = useState(rowData.is_published)
  const { isLoading, startFetch } = useFetch({
    action: updateAutomation,
    isOpenApi: true,
    onComplete: () => {
      setPublished((prv) => !prv)
      toggle()
    },
  })

  return (
    <>
      <Toggle
        check={published}
        change={() => setOpen(true)}
        disabled={!!(isLoading || !rowData?.is_publishable)}
      />
      <Modal centered isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <WarningOctagon size={20} weight='fill' color='var(--red)' />
        </ModalHeader>
        <ModalBody className='d-flex flex-column'>
          <span className='tw-text-base tw-font-bold'>
            Are you sure you want to change status of this automation?
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' outline={true} onClick={toggle}>
            No
          </Button>
          <Button
            color='danger'
            onClick={() => {
              startFetch({
                id: rowData._id,
                isPublished: !published,
              })
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
