import * as Yup from 'yup'

export const triggers = [
  {
    label: 'Contract Pending Worker Signature',
    value: 'CONTRACTOR_INVITED',
  },
  {
    label: 'Contract Pending Company Signature',
    value: 'CLIENT_INVITED',
  },
  {
    label: 'Contract Activated',
    value: 'CONTRACT_ACTIVATED',
  },
  {
    label: 'Contract Terminated',
    value: 'CONTRACT_TERMINATED',
  },
  {
    label: 'Contract Amended',
    value: 'CONTRACT_AMENDED',
  },
]

export const ACTION_TYPE = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
}

export const actionTypes = [
  { label: 'Email', value: ACTION_TYPE.EMAIL },
  { label: 'Slack', value: ACTION_TYPE.SLACK },
]

export const statuses = [
  { label: 'Published', value: true },
  { label: 'Unpublished', value: false },
]

export const suggestedTemplate = {
  name: 'Custom automation',
  buttonText: 'Create your own',
  category: 'Suggested for you',
  custom: true,
  _id: 'custom-automation',
}

export const delayTypeOfDays = [{ label: 'Working Days', value: 'workingDays' }]

export const schema = Yup.object().shape({
  isPublished: Yup.boolean(),
  steps: Yup.array().when('isPublished', {
    is: true,
    then: () =>
      Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required('Type is required'),
          event: Yup.mixed().when('type', {
            is: 'trigger',
            then: () =>
              Yup.object().required('Event is required for trigger type'),
            otherwise: () => Yup.mixed().notRequired(),
          }),
          to: Yup.array()
            .of(Yup.string().required())
            .when('type', {
              is: 'action',
              then: () =>
                Yup.array()
                  .min(1, 'To must have more than one element for action type')
                  .required('To is required for action type'),
              otherwise: () => Yup.array().notRequired(),
            }),
          subject: Yup.string().when('type', {
            is: 'action',
            then: () =>
              Yup.string()
                .required('Subject is required for action type')
                .min(2, 'Must be more than 1 character'),
            otherwise: () => Yup.string().notRequired(),
          }),
          body: Yup.string().when('type', {
            is: 'action',
            then: () =>
              Yup.string()
                .required('Body is required for action type')
                .min(2, 'Must be more than 1 character'),
            otherwise: () => Yup.string().notRequired(),
          }),
          numberOfDays: Yup.string().when('type', {
            is: 'delay',
            then: () =>
              Yup.string()
                .required('Number of days is required for delay type')
                .min(2, 'Must be more than 1 character'),
            otherwise: () => Yup.string().notRequired(),
          }),
        }),
      ),
    otherwise: () => Yup.array().notRequired(), // If isPublished is false, steps field is not required
  }),
})
