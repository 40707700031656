import { ArrowCircleUp, PencilSimple, TrashSimple } from '@phosphor-icons/react'
import React from 'react'
import { ActionsDropdown } from 'ui'
function TenureRuleLine({ tenureRule, index, onDelete, onEdit, addedDays }) {
  return (
    <div className='tw-flex tw-justify-between tw-border-b tw-border-b-surface-30 tw-py-4'>
      <div className='tw-flex tw-grow tw-flex-col tw-justify-center tw-gap-1'>
        <div className='tw-font-semibold'>
          More than {tenureRule.tenure}{' '}
          {tenureRule.tenure > 1 ? 'years' : 'year'}
        </div>
        <div className='tw-text-text-80'>Rule {index + 1}</div>
      </div>
      <div className='tw-flex tw-items-center tw-justify-end tw-gap-4'>
        <div className='tw-flex tw-flex-col tw-gap-1'>
          <div className='tw-flex tw-items-center tw-gap-2 tw-font-semibold'>
            <div className='tw-flex tw-h-[30px] tw-items-center tw-gap-0.5 tw-rounded-full tw-bg-systemGreen-10 tw-px-2'>
              <span>{addedDays}</span>
              <ArrowCircleUp
                weight='fill'
                className='tw-text-systemGreen'
                size={16}
              />
            </div>
            <span>{tenureRule.amount} days</span>
          </div>
          <div className='tw-text-end tw-text-text-80'>Time off amount</div>
        </div>
        <div>
          <ActionsDropdown
            data={[
              {
                label: 'Edit',
                onClick: () => onEdit(tenureRule),
                icon: <PencilSimple />,
              },
              {
                label: 'Delete',
                className: 'tw-text-red',
                onClick: () => onDelete(tenureRule),
                icon: <TrashSimple className='tw-text-red' />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default TenureRuleLine
