const FEATURE_FLAGS = {
  LOCK_PERSONAL_INFO: true,
  NEW_PAYONEER_INTEGRATION: true,

  SENTRY_LOGGING: !!JSON.parse(process.env.REACT_APP_SENTRY_LOGGING ?? false),

  REMOTEPASS_CARDS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_CARDS ?? false,
  ),
  REMOTEPASS_HISTORY: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_HISTORY ?? false,
  ),
  REMOTEPASS_EOR: !!JSON.parse(process.env.REACT_APP_REMOTEPASS_EOR ?? false),
  REMOTEPASS_ANIMATED_CARD: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_ANIMATED_CARD ?? false,
  ),
  REMOTEPASS_PHYSICAL_CARDS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_PHYSICAL_CARDS ?? false,
  ),
  REMOTEPASS_PERKS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_PERKS ?? false,
  ),
  REMOTEPASS_REFERRALS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_REFERRALS ?? false,
  ),
  REMOTEPASS_REFERRALS_REGISTRATION_ON_SIGNUP: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_REFERRALS_REGISTRATION_ON_SIGNUP ?? false,
  ),

  REMOTEPASS_PAYROLL_APPROVAL_PROCESS: !!JSON.parse(
    process.env.REACT_APP_PAYROLL_APPROVAL_PROCESS ?? false,
  ),

  PAYMENT_METHOD_BREX: !!JSON.parse(
    process.env.REACT_APP_PAYMENT_METHOD_BREX ?? false,
  ),

  REMOTEPASS_REPORTS_PAGE: !!JSON.parse(
    process.env.REACT_APP_REPORTS_PAGE ?? false,
  ),

  COMPANY_ARCHIVE_POPUP: !!JSON.parse(
    process.env.REACT_APP_COMPANY_ARCHIVE_POPUP ?? false,
  ),

  PAYSEND_INTEGRATION: !!JSON.parse(
    process.env.REACT_APP_PAYSEND_INTEGRATION ?? false,
  ),

  INSURANCE_BANNER: !!JSON.parse(
    process.env.REACT_APP_INSURANCE_BANNER ?? false,
  ),

  SIDEBAR_LINK_EXPENSES: !!JSON.parse(
    process.env.REACT_APP_SIDEBAR_LINK_EXPENSES ?? false,
  ),

  DEPOSITS_REFUNDS_CLIENT_TAB: !!JSON.parse(
    process.env.REACT_APP_DEPOSITS_REFUNDS_CLIENT_TAB ?? false,
  ),

  SIDEBAR_LINK_TIME_OFF: !!JSON.parse(
    process.env.REACT_APP_SIDEBAR_LINK_TIME_OFF ?? false,
  ),

  CUSTOM_FIELDS: !!JSON.parse(process.env.REACT_APP_CUSTOM_FIELDS ?? false),

  PENDING_COMPLIANCE_REVIEW: !!JSON.parse(
    process.env.REACT_APP_PENDING_COMPLIANCE_REVIEW ?? false,
  ),

  DOCUMENTS_BG_CHECK: !!JSON.parse(
    process.env.REACT_APP_DOCUMENTS_BG_CHECK ?? false,
  ),

  OPEN_API: !!JSON.parse(process.env.REACT_APP_OPEN_API ?? false),

  INTERNAL_ACCOUNTS: !!JSON.parse(
    process.env.REACT_APP_INTERNAL_ACCOUNTS ?? false,
  ),

  EXTERNAL_INVOICES: parseFlagEnv(process.env.REACT_APP_EXTERNAL_INVOICES),
  NEW_DIRECT_EMPLOYEE: parseFlagEnv(process.env.REACT_APP_NEW_DIRECT_EMPLOYEE),

  ORGANIZATION_CHART: parseFlagEnv(process.env.REACT_APP_ORGANIZATION_CHART),

  NEW_CLIENT_CONTRACT_LIST: parseFlagEnv(
    process.env.REACT_APP_NEW_CLIENT_CONTRACT_LIST,
  ),

  TIME_OFF_POLICIES: parseFlagEnv(process.env.REACT_APP_TIME_OFF_POLICIES),

  BULK_CONTRACT_CREATION: parseFlagEnv(
    process.env.REACT_APP_BULK_CONTRACT_CREATION,
  ),

  SLACK_NOTIFICATION: parseFlagEnv(process.env.REACT_APP_SLACK_NOTIFICATION),

  BULK_OPERATIONS: parseFlagEnv(process.env.REACT_APP_BULK_OPERATIONS),

  BULK_INVITATIONS: parseFlagEnv(process.env.REACT_APP_BULK_INVITATIONS),
  BILL_PAYMENTS: parseFlagEnv(process.env.REACT_APP_BILL_PAYMENTS),
  CLIENT_NEW_BANNER: parseFlagEnv(process.env.REACT_APP_CLIENT_NEW_BANNER),
  PAYG_TIMEOFF: parseFlagEnv(process.env.REACT_APP_PAYG_TIMEOFF),
  AUTOMATION_COMPANIES_TAB: parseFlagEnv(
    process.env.REACT_APP_AUTOMATION_COMPANIES_TAB,
  ),
  TIMEOFF_DOCUMENTS_UPLOAD: parseFlagEnv(
    process.env.REACT_APP_TIMEOFF_DOCUMENTS_UPLOAD,
  ),
}

function parseFlagEnv(env, { defaultValue = false } = {}) {
  return !!JSON.parse(env ?? defaultValue)
}

export default FEATURE_FLAGS
