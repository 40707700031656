import { yupResolver } from '@hookform/resolvers/yup'
import { FilePlus, TrashSimple, XCircle } from '@phosphor-icons/react'
import React, { useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Container, Spinner } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import UploadPreview from '../../../components/Common/upload-preview'
import ControlledDropzoneInput from '../../../components/controlled-dropzone-input'
import Head from '../../../components/head'
import ModalHeader from '../../../components/ModalHeader'
import { BackgroundDots } from '../../../components/ui/background-dots'
import Button from '../../../components/ui/button'
import { toBase64 } from '../../../helpers/helper'
import { useFetch } from '../../../helpers/hooks'
import { uploadTempFile } from '../../../services/api'
import {
  createBill,
  createVendor,
  getBill,
  getVendors,
  updateBill,
} from '../../../services/api-bill-payments'
import { mapListToOption } from '../../../utils/map-to-option'
import { MAX_SIZE_READABLE } from '../../Contract/utils/constants'
import CreateBillForm, { defaultBillInvoiceItem } from './create-bill-form'
import { CreateBillPreview } from './create-bill-preview'

export function CreateAndEditBill() {
  const formPreviewRef = useRef(null)

  const history = useHistory()
  const { billId } = useParams()

  const isEditPath = history.location.pathname.includes('bills/edit')

  const isEdit = isEditPath && !!billId

  const {
    integration_categories: integrationCategories = [],
    currencies = [],
  } = useSelector((state) => state?.Layout?.staticData ?? {})

  function handleReturn() {
    history.push(history.location.state?.backRoute ?? '/bills')
  }

  const { data: vendors, isLoading: isLoadingVendor } = useFetch({
    action: getVendors,
    autoFetch: true,
  })

  const [previewImage, setPreviewImage] = useState(null)

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: { items: [{ ...defaultBillInvoiceItem }] },
    resolver: yupResolver(
      yup.object().shape({
        vendor_id: yup
          .mixed()
          .test('test-vendor_id', 'Should be a valid vendor', (value) => {
            // Checking this shape: { label: string, value: string, __isNew__: boolean }
            if (value?.__isNew__ === true) {
              return typeof value?.value === 'string'
            }

            if (vendors.length >= 0) {
              return !vendors.some(
                (vendor) => String(vendor.value) === String(value?.value),
              )
            }

            if (['string', 'number'].includes(typeof value?.value)) {
              return true
            }

            return true
          })
          .label('Vendor'),
        category_id: yup
          .string()
          .oneOf(integrationCategories.map((c) => String(c.id)))
          .required()
          .label('Category'),
        issue_date: yup.date().required().label('Issue date'),
        due_date: yup.date().required().label('Due date'),
        total_amount: yup
          .number()
          .typeError('Please enter a valid number')
          .required()
          .label('Total amount'),
        currency_id: yup
          .string()
          .oneOf(currencies.map((c) => String(c.id)))
          .required()
          .label('Currency'),
        items: yup.array().of(
          yup.object().shape({
            description: yup.string().required().label('Description'),
            amount: yup
              .number()
              .typeError('Please enter a valid number')
              .required()
              .label('Amount'),
            category_id: yup
              .string()
              .oneOf(integrationCategories.map((c) => String(c.id)))
              .required()
              .label('Category'),
          }),
        ),
      }),
    ),
  })

  const { startFetch: uploadFile, isLoading: isUploading } = useFetch({
    action: uploadTempFile,
    onComplete: (data) => {
      if (data.full_path) {
        setPreviewImage((prev) => ({ ...prev, data: data.full_path }))
        setValue('file', data?.path)
      } else {
        toastr.error('Error uploading file')
      }
    },
    onError: (err) => {
      toastr.error(err, 'Error uploading file')
    },
  })

  async function handleDropAccepted(files) {
    const [rawFile] = files ?? []

    const base64File = await toBase64(rawFile)
    setPreviewImage({ data: base64File, type: rawFile.type })

    // Upload the file to the server
    uploadFile({ file: rawFile, type: 'works' })
  }

  const { isLoading: isLoadingBill } = useFetch(
    {
      action: getBill,
      body: { id: billId },
      autoFetch: isEdit,
      onComplete: (data) => {
        reset({
          vendor_id: { label: data?.vendor?.name, value: data?.vendor?.id },
          category_id: data?.category?.id,
          issue_date: data?.issue_date,
          due_date: data?.due_date,
          total_amount: data?.amount,
          currency_id: data?.currency?.id,
          items: data?.items,
        })

        if (data?.file?.pdf) {
          setPreviewImage({ data: data?.file?.pdf, type: 'application/pdf' })
        }
      },
    },
    [billId, isEditPath],
  )

  const loadingData = isLoadingVendor || !integrationCategories || isLoadingBill

  const values = useWatch({ control })

  const [previewingData, setPreviewingData] = useState(null)

  function handleNewVendor({ next, vendorId }) {
    if (values.vendor_id?.__isNew__ && vendorId) {
      history.push(`/bills/create/go-to-vendor/${vendorId}`)
      return
    }

    next?.()
  }

  const { startFetch: _createBill, isLoading: creatingBill } = useFetch({
    action: createBill,
    onComplete: (data, body) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while creating the bill')
      } else {
        toastr.success('Bill created successfully')
        handleNewVendor({
          next: () => history.push('/bills'),
          vendorId: body?.vendor_id,
        })
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: _updateBill, isLoading: updatingBill } = useFetch({
    action: updateBill,
    onComplete: (data, body) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while updating the bill')
      } else {
        toastr.success('Bill updated successfully')
        handleNewVendor({
          next: () => history.push('/bills'),
          vendorId: body?.vendor_id,
        })
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const { startFetch: createTheVendor, isLoading: creatingVendor } = useFetch({
    action: createVendor,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Something went wrong while creating the vendor')
      } else {
        handleCreateUpdateBill({ vendor_id: data?.id })
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const submitLoading = creatingVendor || creatingBill || updatingBill

  function onSubmit() {
    setPreviewingData(true)
    // scroll to the top of the form preview
    formPreviewRef.current.scrollTop = 0
  }

  function handleCreateUpdateBill(additionalValues) {
    const body = {
      amount: values.total_amount,
      currency_id: values.currency_id,
      category_id: values.category_id,
      issue_date: values.issue_date,
      due_date: values.due_date,
      vendor_id: additionalValues?.vendor_id || values.vendor_id?.value,
      items: values.items.map((item) => ({ ...item })),
      file: values?.file,
    }

    if (isEdit) {
      _updateBill({ ...body, id: billId })
    } else {
      _createBill(body)
    }
  }

  function handleSave() {
    if (values.vendor_id?.__isNew__) {
      createTheVendor({ name: values.vendor_id.label })
    } else {
      handleCreateUpdateBill()
    }
  }

  return (
    <Container fluid className='px-0'>
      <Head title='Create bill' />

      <ModalHeader action={handleReturn}>
        <h2 className='tw-mb-0 tw-text-center tw-text-sm tw-font-bold'>
          Bill creation
        </h2>
      </ModalHeader>

      <div className='tw-relative tw-grid tw-h-[calc(100vh-var(--header-height))] tw-overflow-hidden md:tw-grid-cols-2'>
        <BackgroundDots />
        <div
          className={cn(
            'tw-relative tw-gap-4 tw-overflow-auto tw-p-6',
            !previewImage
              ? 'tw-flex tw-flex-col'
              : 'tw-grid tw-grid-rows-[auto_1fr] [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain',
          )}
        >
          <div className='tw-flex tw-justify-end'>
            {!previewImage ? (
              <div className='tw-h-10' />
            ) : (
              <Button
                size='sm'
                className='tw-size-10 !tw-border-surface-30 !tw-bg-white !tw-text-systemRed-100 hover:!tw-bg-systemRed-10'
                icon={<TrashSimple size={16} />}
                aria-label='Delete invoice'
                onClick={() => {
                  setValue('file', undefined)
                  setPreviewImage(null)
                }}
                type='button'
              />
            )}
          </div>

          <div
            className={cn(
              'tw-relative tw-m-auto tw-w-full tw-max-w-[30rem] tw-rounded-lg tw-border tw-border-surface-30 tw-bg-white tw-shadow-[0px_16px_40px_0px_rgba(0,0,0,0.04)]',
              !previewImage && 'tw-p-6',
            )}
          >
            {loadingData ? (
              <div className={previewImage && 'tw-p-6'}>
                <Spinner color='primary' size='sm' />
                <p className='tw-mb-0 tw-mt-1'>Loading preview ... </p>
              </div>
            ) : previewImage ? (
              <UploadPreview
                preview={previewImage}
                imagePreviewClassName='tw-contents'
                className='tw-size-full tw-min-h-[42rem]'
              />
            ) : (
              <>
                <XCircle className='tw-text-xl tw-text-systemRed-100' />
                <h3 className='tw-mb-2 tw-text-xl tw-font-semibold tw-text-secondary-120'>
                  No invoice added
                </h3>
                <p className='tw-mb-6 tw-text-sm tw-font-medium tw-text-text-80'>
                  Add a valid invoice and we will fill out the fields for you
                </p>

                <ControlledDropzoneInput
                  control={control}
                  name='file_raw'
                  id='file_raw'
                  className='tw-rounded-lg tw-p-6 tw-transition-colors hover:tw-bg-primary-30'
                  onDropAccepted={handleDropAccepted}
                  accept={{ 'application/pdf': ['.pdf'] }}
                >
                  <div className=''>
                    <Button
                      icon={<FilePlus size={20} />}
                      color='link'
                      className='!tw-p-0'
                      tag='div'
                    >
                      <div className='tw-hidden md:tw-block'>
                        Drop file here or click to upload
                      </div>
                      <div className='md:tw-hidden'>Click to upload</div>
                    </Button>

                    <p className='tw-mb-0 tw-mt-2 tw-text-xs tw-text-secondary-80'>
                      *PDF only, max file size {MAX_SIZE_READABLE}
                    </p>
                  </div>
                </ControlledDropzoneInput>
              </>
            )}
          </div>
        </div>

        <BillDataProvider
          data={{
            vendors,
            categoriesList: integrationCategories.map((c) =>
              mapListToOption(c),
            ),
            loadingData,
          }}
        >
          <div
            className='tw-relative tw-overflow-auto tw-bg-white'
            ref={formPreviewRef}
          >
            {previewingData ? (
              <CreateBillPreview
                values={values}
                onBack={() => {
                  setPreviewingData(false)
                }}
                onSave={handleSave}
                submitLoading={submitLoading}
              />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CreateBillForm
                  control={control}
                  setValue={setValue}
                  nextDisabled={isUploading}
                />
              </form>
            )}
          </div>
        </BillDataProvider>
      </div>
    </Container>
  )
}

const billDataContext = React.createContext(null)

function BillDataProvider({ data, children }) {
  return (
    <billDataContext.Provider value={data}>{children}</billDataContext.Provider>
  )
}

export function useBillData() {
  return React.useContext(billDataContext)
}
