import React, { useMemo, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Sparkle } from '@phosphor-icons/react'
import { AutomationsCard } from './automations-card'
import { useAutomations } from './use-automations'
import Loader from '../../../../components/ui/loader'
import groupBy from 'lodash/groupBy'
import keys from 'lodash/keys'
import { cn } from 'ui'
import entries from 'lodash/entries'
import { suggestedTemplate } from './constants'

export function AutomationsTemplatesModal(props) {
  const { toggle, isOpen } = props
  const [category, setCategory] = useState('')
  const { automations: templates, isLoading } = useAutomations({
    onlyTemplates: true,
    limit: 100,
  })

  const categories = keys(groupBy(templates, 'category')) ?? []

  const filteredTemplates = useMemo(() => {
    const withSuggested = [suggestedTemplate, ...templates]
    const filtered =
      category && category !== 'All'
        ? withSuggested.filter((i) => i?.category === category)
        : withSuggested
    return entries(groupBy(filtered, 'category'))
  }, [category, templates])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='xl'>
      <ModalHeader toggle={toggle}>Choose an option</ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className='tw-flex tw-flex-row'>
              {['All', ...categories].map((i) => (
                <button
                  onClick={() => setCategory(i)}
                  className={cn(
                    'tw-m-0.5 tw-rounded-full tw-border tw-border-surface-30 tw-px-4 tw-py-2',
                    category === i &&
                      'tw-border-transparent tw-bg-primary tw-text-white',
                  )}
                  key={i}
                >
                  {i}
                </button>
              ))}
            </div>

            {filteredTemplates?.map(([category, items]) => (
              <>
                <div className='tw-my-6 tw-flex tw-flex-row tw-items-center tw-gap-2'>
                  <Sparkle className='tw-text-primary' size={20} />
                  <span className='tw-text-lg tw-font-medium'>{category}</span>
                </div>
                <div className='tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-2 lg:tw-grid-cols-3'>
                  {items?.map((t) => (
                    <AutomationsCard
                      key={t._id}
                      containerClassName='tw-bg-surface-10'
                      id={t._id}
                      title={t.name}
                      buttonText={t?.buttonText}
                      description={t?.description}
                      channels={t?.action_channels}
                      custom={t?.custom}
                    />
                  ))}
                </div>
              </>
            ))}
          </>
        )}
      </ModalBody>
    </Modal>
  )
}
