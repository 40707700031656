import React from 'react'
import { CaretUp, CaretDown, Lightning } from '@phosphor-icons/react'
import { Card } from 'reactstrap'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { useFormContext, Controller, useFormState } from 'react-hook-form'
import { cn } from 'ui'
import { triggers } from './constants'

export function Trigger(props) {
  const { id, index } = props
  const { setValue, watch } = useFormContext()
  const { errors } = useFormState()
  const focused = watch('focusedNode') === id
  return (
    <div
      className={cn(
        'tw-rounded-md',
        focused ? 'tw-border-2 tw-border-primary-60' : undefined,
        errors?.steps?.[index] ? 'tw-border-2 tw-border-red' : undefined,
      )}
    >
      <Card className='!tw-mb-0 tw-flex !tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-p-4'>
        <div className='tw-rounded tw-bg-primary-20 tw-p-2'>
          <Lightning size={25} className='tw-text-primary' />
        </div>
        <div className='tw-flex tw-flex-1 tw-flex-col'>
          <span className='tw-font-medium'>When this happens</span>
          <span>Choose trigger details</span>
        </div>
        <button
          onClick={() => setValue('focusedNode', focused ? null : id)}
          className='tw-rounded tw-bg-primary-20 tw-p-2'
        >
          {focused ? (
            <CaretUp size={25} className='tw-text-primary' />
          ) : (
            <CaretDown size={25} className='tw-text-primary' />
          )}
        </button>
      </Card>

      {focused ? (
        <div className='tw-flex tw-flex-col tw-gap-3.5 tw-p-3.5'>
          <Controller
            name={`steps.${index}.event`}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                options={triggers}
                placeholder='Select trigger'
                onChange={onChange}
                value={value}
                isClearable
              />
            )}
          />
        </div>
      ) : null}
    </div>
  )
}
