import {
  FunnelSimple,
  Invoice,
  Money,
  Plus,
  Storefront,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import toastr from 'toastr'

import { Avatar, cn } from 'ui'
import Head from '../../../components/head'
import { PageNav, useActiveTab } from '../../../components/page-nav'
import SearchBar from '../../../components/SearchBar'
import Button from '../../../components/ui/button'
import { CheckItem } from '../../../components/ui/check-item'
import DataTable from '../../../components/ui/data-table'
import PageHeading from '../../../components/ui/page-heading'
import Pagination from '../../../components/ui/pagination'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import { useUrlStateV2 } from '../../../helpers/hooks/use-url-state'
import permissions from '../../../helpers/permissions'
import { getBills, getVendors } from '../../../services/api-bill-payments'
import { updateToPayList } from '../../../store/payment/actions'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { mapListToOption } from '../../../utils/map-to-option'
import { EmptyState } from '../../review-center/empty-state'
import BillCategoryIcon from '../bill-category-icon'
import BillDetailsButton, {
  BillDueDate,
  BillStatusBadge,
} from './bill-details-button'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'

export const BILL_STATUS = {
  DRAFT: 'draft',
  PENDING: 'pending_approval',
  READY: 'pending_payment',
  COMPLETED: 'completed',
}

const PageHeader = () => {
  const { hasAccess } = usePermissions()
  return (
    <PageHeading className='tw-mb-4'>
      <PageHeading.Title>Bills</PageHeading.Title>
      <PageHeading.ActionGroup className='tw-flex-wrap tw-gap-x-2'>
        <Button
          className='!tw-border-0 !tw-bg-white !tw-text-black'
          icon={<Storefront size={20} />}
          to='/bills/vendors'
          tag={Link}
        >
          Manage Vendors
        </Button>

        {hasAccess(permissions.ManageBill) && (
          <Button icon={<Plus size={20} />} tag={Link} to='/bills/create'>
            Add Invoice
          </Button>
        )}
      </PageHeading.ActionGroup>
    </PageHeading>
  )
}

const tabsData = [
  {
    label: 'Draft',
    key: BILL_STATUS.DRAFT,
  },
  { label: 'For Approval', key: BILL_STATUS.PENDING },
  {
    label: 'Ready To Pay',
    key: BILL_STATUS.READY,
  },
  { label: 'Completed', key: BILL_STATUS.COMPLETED },
]

const TabHeader = ({ activeTab }) => (
  <PageNav className='!tw-mb-4 !tw-flex-wrap tw-rounded tw-bg-white'>
    {tabsData.map((data) => (
      <PageNav.Item key={data.key}>
        <PageNav.Link
          to={`/bills?tab=${data.key}`}
          tag={Link}
          isActive={activeTab === data.key}
          className='!tw-flex tw-items-center tw-gap-2'
        >
          {data.label}
        </PageNav.Link>
      </PageNav.Item>
    ))}
  </PageNav>
)

export function BillInfoText({ title, subTitle, className }) {
  return (
    <span
      className={cn(
        'tw-text-right tw-text-sm tw-font-normal tw-text-text-60',
        className,
      )}
    >
      <div className='tw-text-text'>{title}</div>
      {subTitle}
    </span>
  )
}

const Body = ({ activeTab }) => {
  const {
    value: {
      selectedItem,
      due_date: dueDate,
      vendor,
      category,
      min_amount: minAmount,
      max_amount: maxAmount,
    },
    updater: setUrlState,
  } = useUrlStateV2({
    selectedItem: undefined,
    due_date: undefined,
    vendor: undefined,
    category: undefined,
    min_amount: undefined,
    max_amount: undefined,
  })
  function setSelectedItem(item) {
    setUrlState({ selectedItem: item })
  }

  const [selectedItems, setSelectedItems] = useState([])
  const history = useHistory()
  const location = history.location
  const searchParams = new URLSearchParams(location.search)
  const currentPage = searchParams.get('page') ?? 1

  const integrationCategories = useSelector(
    (state) => state.Layout?.staticData?.integration_categories,
  )
  const { id: selectedCategoryId, name: selectedCategoryName } =
    integrationCategories?.find((cat) => cat.name === category) ?? {}
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()

  const {
    data: bills,
    isLoading,
    startFetch: fetchBills,
    paginator,
  } = useFetch(
    {
      action: getBills,
      autoFetch: true,
      body: {
        vendor_id: vendor,
        due_date: dueDate,
        min_amount: minAmount,
        max_amount: maxAmount,
        category_id: selectedCategoryId,
        status: activeTab,
        page: currentPage,
      },
      onError: (error) => toastr.error(error),
    },
    [
      vendor,
      dueDate,
      minAmount,
      maxAmount,
      selectedCategoryId,
      activeTab,
      currentPage,
    ],
  )
  const isAllSelected = selectedItems.length === bills?.length
  const isFiltering =
    vendor || dueDate || selectedCategoryId || maxAmount || minAmount

  const columns = [
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cellData }) =>
        !cellData ? (
          'N/A'
        ) : (
          <div className='tw-flex tw-gap-2'>
            <BillCategoryIcon category={cellData?.id} /> {cellData?.name}
          </div>
        ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Created on
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'created_at',
      Cell: ({ cellData, rowData }) => (
        <BillInfoText
          title={format(new Date(cellData), 'dd/MM/yyyy')}
          subTitle={
            rowData.creator_name &&
            `By ${rowData.creator_name} ${user.id === rowData.creator_id ? '(You)' : ''}`
          }
          className='tw-text-left'
        />
      ),
    },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Due date
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'due_date',
      Cell: ({ cellData }) => <BillDueDate date={cellData} />,
    },
    { Header: 'Vendor', accessor: 'vendor.name' },
    {
      Header: (
        <span className='tw-flex tw-items-center tw-gap-x-2'>
          Amount
          <FunnelSimple size={20} className='tw-fill-text-60' />
        </span>
      ),
      accessor: 'amount',
      Cell: ({ rowData }) => {
        const formatter = getCurrencyFormatter(rowData.currency.code)
        return <span>{formatter.format(rowData.amount)}</span>
      },
    },
    {
      Header: 'Invoice ID',
      accessor: 'name',
    },
    activeTab === BILL_STATUS.PENDING
      ? {
          Header: 'Next Approver',
          accessor: 'next_approver',
          Cell: ({ cellData, rowData }) => {
            const approverIndex = rowData.timeline?.findIndex(
              (item) => item.actor_id === cellData.id,
            )
            return (
              <span className='tw-flex tw-gap-2'>
                <span className='tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-secondary-20 tw-px-2 tw-py-1 tw-text-sm tw-font-bold tw-text-black'>
                  {`${approverIndex + 1}/${rowData.timeline?.length}`}
                </span>

                <span className='tw-flex tw-items-center tw-gap-1 tw-rounded-[32px] tw-bg-secondary-20 tw-px-2 tw-py-1'>
                  <Avatar photo={cellData.photo} name={cellData.name} />
                  <span className='tw-text-sm tw-font-semibold tw-text-black'>
                    {cellData.name}
                  </span>
                </span>
              </span>
            )
          },
        }
      : {
          Header: 'Status',
          accessor: 'status.name',
          Cell: ({ cellData }) => <BillStatusBadge status={cellData} />,
        },
    {
      Cell: ({ rowData }) => (
        <BillDetailsButton
          bill={rowData}
          fetchBills={fetchBills}
          activeTab={tabsData.find((tab) => tab.key === activeTab)}
          onPayClick={() => {
            onPayClick({
              paymentIds: [rowData.payment_item_id],
              location,
              dispatch,
              history,
            })
          }}
          activeItem={selectedItem || undefined}
          showItem={() => setSelectedItem(rowData?.name)}
          closeItem={() => setSelectedItem(undefined)}
        />
      ),
    },
    activeTab === BILL_STATUS.READY && {
      Header: (
        <Button
          color='link'
          onClick={() => setSelectedItems([])}
          className='tw-w-28'
        >
          {isAllSelected ? `Deselect ` : 'Select '} All
        </Button>
      ),
      Cell: ({ rowData }) => {
        const isItemSelected = selectedItems.find(
          (item) => item.id === rowData.id,
        )
        return (
          <span className='tw-flex tw-justify-center'>
            <CheckItem
              className='tw-w-fit'
              checked={isAllSelected || isItemSelected}
              onChange={() => {
                setSelectedItems(
                  isItemSelected
                    ? [
                        ...selectedItems.filter(
                          (item) => item.id !== rowData.id,
                        ),
                      ]
                    : [...selectedItems, rowData],
                )
              }}
            />
          </span>
        )
      },
    },
  ].filter(Boolean)

  return (
    <div className='tw-flex tw-flex-col tw-gap-6 tw-rounded tw-bg-white tw-px-6 tw-pb-16 tw-pt-6'>
      <BodyHeader
        isLoading={isLoading}
        location={location}
        history={history}
        dispatch={dispatch}
        itemsToPay={selectedItems}
      />
      {!(bills?.length === 0 && !isFiltering) && (
        <FilterRow
          isLoading={isLoading && !isFiltering}
          categories={integrationCategories}
          selectedCategory={
            selectedCategoryId
              ? { label: selectedCategoryName, value: selectedCategoryId }
              : undefined
          }
        />
      )}
      {isLoading ? (
        <span className='tw-flex tw-flex-col tw-gap-2'>
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
          <Shimmer width='100%' height={50} />
        </span>
      ) : bills?.length === 0 ? (
        <EmptyState
          title='You have no bill to show'
          textElement={
            !isFiltering && activeTab === BILL_STATUS.DRAFT
              ? 'You can click Add Invoice button and create one.'
              : ''
          }
          pictureElement={
            <Invoice
              weight='duotone'
              className='tw-mb-4 tw-fill-primary'
              size={250}
            />
          }
          className='tw-shadow-none'
        />
      ) : (
        <>
          <DataTable columns={columns} data={bills} striped responsive />
          <span className='tw-flex tw-justify-end'>
            <Pagination
              activePage={Number(currentPage)}
              onChange={(page) => {
                searchParams.set('page', page)
                history.replace(
                  `${history.location.pathname}?${searchParams.toString()}`,
                )
              }}
              itemsCountPerPage={paginator?.per_page}
              totalItemsCount={paginator?.total ?? 0}
            />
          </span>
        </>
      )}
    </div>
  )
}

const onPayClick = ({ dispatch, history, paymentIds, location }) => {
  dispatch(updateToPayList(paymentIds))
  history.push(
    `/pay-invoices?from=${encodeURIComponent(location.pathname + location.search)}&only_bank=true`,
  )
}

const BodyHeader = ({
  isLoading,
  location,
  dispatch,
  history,
  itemsToPay = [],
}) => {
  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })
  const companyCurrency = useSelector(
    (state) => state.userProfile?.userProfile?.company?.currency?.code,
  )
  const formatter = getCurrencyFormatter(companyCurrency)
  const { hasAccess } = usePermissions()
  const paymentIds = itemsToPay.map((item) => item.payment_item_id)

  return (
    <span className='tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-2'>
      <span>
        {!isLoading ? (
          <>
            <div className='tw-text-2xl'>
              {tabsData.find((data) => data.key === activeTab).label}
            </div>
          </>
        ) : (
          <>
            <Shimmer className='tw-mb-2' width={130} />
            <Shimmer height={32} />
          </>
        )}
      </span>

      {activeTab === BILL_STATUS.READY &&
        hasAccess(permissions.PrepareTransactions) && (
          <Button
            disabled={isLoading || itemsToPay.length === 0}
            icon={<Money />}
            onClick={() =>
              onPayClick({ paymentIds, location, dispatch, history })
            }
          >
            {itemsToPay.length === 0
              ? 'Select To Pay'
              : 'Pay ' +
                formatter.format(
                  itemsToPay.reduce((acc, item) => acc + item.trans_amount, 0),
                )}
          </Button>
        )}
    </span>
  )
}

const FilterRowWrapper = ({ children, className }) => (
  <span
    className={cn(
      'tw-flex tw-flex-wrap tw-gap-x-2 tw-gap-y-4 [&>*]:!tw-w-full [&>*]:md:!tw-min-w-60 [&>*]:md:tw-flex-1',
      className,
    )}
  >
    {children}
  </span>
)

const FilterRow = ({ isLoading, categories, selectedCategory }) => {
  const { data: vendors, isLoading: fetchingVendors } = useFetch({
    action: getVendors,
    autoFetch: true,
    onError: (error) => toastr.error(error),
  })
  const {
    updater: setUrlState,
    value: {
      min_amount: minAmount,
      max_amount: maxAmount,
      due_date: dueDate,
      vendor,
    },
  } = useUrlStateV2({ due_date: undefined }, { replaceRoute: true })

  if (isLoading) {
    return (
      <FilterRowWrapper>
        {new Array(5).fill(0).map((_, index) => (
          <Shimmer key={index} />
        ))}
      </FilterRowWrapper>
    )
  }

  return (
    <FilterRowWrapper>
      <CustomDatePicker
        placeholder='Due Date'
        clearable
        handleClear={() => setUrlState({ due_date: null })}
        wrapperClassName='[&>div>div>div]:tw-h-[56px] [&>div>div>div]:!tw-flex [&>div>div>div]:tw-items-center'
        inputClassName='!tw-h-[56px]'
        value={dueDate}
        handleOnChange={(value) => {
          const formattedDate = format(value, 'yyyy-MM-dd')
          setUrlState({ due_date: formattedDate })
          return formattedDate
        }}
      />

      <CustomSelect
        placeholder='Category'
        isClearable
        options={categories?.map(mapListToOption)}
        onChange={(val) => setUrlState({ category: val?.label })}
        value={selectedCategory}
        selectStyles={{ control: () => ({ height: '56px' }) }}
      />

      <CustomSelect
        placeholder='Vendor'
        isClearable
        options={vendors?.map(mapListToOption)}
        onChange={(val) => setUrlState({ vendor: val?.value })}
        value={vendors?.find((_vendor) => _vendor.id === vendor)}
        selectStyles={{ control: () => ({ height: '56px' }) }}
        isDisabled={fetchingVendors}
      />

      <SearchBar
        type='number'
        placeholder='Min Amount'
        inputClassName='!tw-h-14 !tw-px-3'
        hideIcon
        query={minAmount}
        onQueryChanged={(val) => setUrlState({ min_amount: val || undefined })}
      />

      <SearchBar
        type='number'
        placeholder='Max Amount'
        inputClassName='!tw-h-14 !tw-px-3'
        hideIcon
        query={maxAmount}
        onQueryChanged={(val) => setUrlState({ max_amount: val || undefined })}
      />
    </FilterRowWrapper>
  )
}

export default function Bills() {
  const { activeTab } = useActiveTab({ defaultTab: BILL_STATUS.DRAFT })

  return (
    <div className='page-content'>
      <Head title='Bills' />
      <PageHeader />
      <TabHeader activeTab={activeTab} />
      <Body activeTab={activeTab} />
    </div>
  )
}
