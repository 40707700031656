import React, { cloneElement } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import { cn } from 'ui'

export default function UploadPreview({
  preview,
  className = 'tw-h-full tw-w-full',
  imagePreviewClassName,
  style,
}) {
  const classList = cn('tw-rounded tw-border', className)

  return preview?.type === 'application/pdf' ? (
    <iframe
      src={preview?.data + '#toolbar=0&navpanes=0&scrollbar=0'}
      className={classList}
      style={style}
    />
  ) : (
    <div
      style={style}
      className={cn('tw-overflow-auto', imagePreviewClassName)}
    >
      <img alt='' src={preview?.data} className={classList} />
    </div>
  )
}

export function DocumentPreviewModal({ isOpen, toggle, ...props }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <UploadPreview {...props} />
      </ModalBody>
    </Modal>
  )
}

export function DocumentPreview({ children, ...props }) {
  const [isOpen, setIsOpen] = React.useState(false)
  function toggle() {
    return setIsOpen((prev) => !prev)
  }

  return (
    <>
      {typeof children === 'function'
        ? children({ toggle })
        : cloneElement(children, { onClick: toggle })}

      <DocumentPreviewModal isOpen={isOpen} toggle={toggle} {...props} />
    </>
  )
}
