import React from 'react'
import { Controller } from 'react-hook-form'

export function TitleEditText() {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value } }) => (
        <input
          type='text'
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          className='tw-rounded tw-border tw-border-transparent tw-bg-transparent tw-p-2 tw-text-center tw-text-black hover:tw-border-secondary-50 focus:tw-border-primary-100 focus:tw-outline-none'
        />
      )}
      name='name'
    />
  )
}
