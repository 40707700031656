import { yupResolver } from '@hookform/resolvers/yup'
import {
  Bank,
  CreditCard,
  Diamond,
  DownloadSimple,
  Eye,
  FileCsv,
  Warning,
  X,
  Invoice,
  CheckCircle,
} from '@phosphor-icons/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ActionsDropdown } from 'ui'
import ControlledSelect from '../../components/ControlledSelect'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../components/NoContent'
import SearchBar from '../../components/SearchBar'
import BadgeX from '../../components/Table/BadgeX'
import StyledTd from '../../components/Table/StyledTd'
import StyledTh from '../../components/Table/StyledTh'
import { StyledH3, StyledH6 } from '../../components/Typo'
import Alert from '../../components/ui/alert'
import Button from '../../components/ui/button'
import ExpandIcon from '../../components/ui/expand-icon/ExpandIcon'
import Pagination from '../../components/ui/pagination'
import Shimmer from '../../components/ui/shimmer'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  clientCancelTransactions,
  downloadTransactionDEWSFile,
  downloadWithdrawalReceipt,
  exportStatement,
  exportTransaction,
  exportTransactions,
  getPaymentHistory,
  getTransactionDetails,
  getTransactionsMonths,
  getTrxDetails,
  markDeTransactionAsConfirmed,
} from '../../services/api'
import openFile from '../../utils/file/open'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { validateChecks } from '../ProfileSettings/ProfileInfo'
import noTransactionsImage from './../../assets/images/no-transactions.svg'
import AccountInfo from './components/AccountInfo'
import DetailModal from './components/DetailModal'
import { DownloadStatementModal } from './download-statement-modal'
import { icons } from './payment-method-icons/icons'
import trxCancelledIllustration from './trx-canceled-illustration.svg'
import openFileV2 from '../../utils/file/open-v2'

function getStatusColor(status) {
  switch (status) {
    case 'Processing':
    case 'pending':
      return 'warning'
    case 'Paid':
    case 'processed':
      return 'success'
    case 'Overdue':
      return 'danger'
    case 'Unpaid':
      return 'danger'
    default:
      return 'primary'
  }
}
function getTooltipText(status) {
  switch (status) {
    case 'Processing':
      return 'Company indicated that they have sent the payment and it has not been received yet'
    case 'Paid':
      return 'Company payment received and disbursed'
    case 'Overdue':
      return 'Company has not paid yet'
  }
}
const INTERCOM_Z_INDEX_MAGIC_NUMBER = '2147483002'

const phProps = { size: 20, weight: 'fill' }
const iconImgProps = { style: { width: 20, height: 20 } }
const defaultIcon = <Diamond {...phProps} />

const PAYMENT_METHOD_TYPES = {
  1: {
    id: '1',
    code: 'cc',
    name: 'Credit Card',
    icon: <CreditCard {...phProps} />,
  },
  2: {
    id: '2',
    code: 'paypal',
    name: 'PayPal',
    icon: <img src={icons.paypal} {...iconImgProps} />,
  },
  3: {
    id: '3',
    code: 'transfer',
    name: 'Bank Transfer',
    icon: <Bank {...phProps} />,
  },
  4: {
    id: '4',
    code: 'sepa',
    name: 'SEPA',
    icon: <img src={icons.sepa} {...iconImgProps} />,
  },
  5: {
    id: '5',
    code: 'ach',
    name: 'ACH',
    icon: <img src={icons.ach} {...iconImgProps} />,
  },
  6: { id: '6', code: 'cash', name: 'Cash Point', icon: defaultIcon },
  7: { id: '7', code: 'payoneer', name: 'Payoneer', icon: defaultIcon },
  8: { id: '8', code: 'wallet', name: 'Wallet', icon: defaultIcon },
  9: {
    id: '9',
    code: 'coinbase',
    name: 'Coinbase',
    icon: <img src={icons.coinbase} {...iconImgProps} />,
  },
  10: { id: '10', code: 'card_top_up', name: 'Top up card', icon: defaultIcon },
  11: {
    id: '11',
    code: 'card_offload',
    name: 'Offload card',
    icon: defaultIcon,
  },
  12: {
    id: '12',
    code: 'balance_addition',
    name: 'Balance addition',
    icon: defaultIcon,
  },
  13: {
    id: '13',
    code: 'balance_deduction',
    name: 'Balance deduction',
    icon: defaultIcon,
  },
  14: {
    id: '14',
    code: 'card_order_fees',
    name: 'Card order fees',
    icon: defaultIcon,
  },
  15: { id: '15', code: 'brex', name: 'Brex', icon: defaultIcon },
  17: { id: '17', code: 'paysend', name: 'Paysend', icon: defaultIcon },
  18: {
    id: '18',
    code: 'coinbase',
    name: 'Coinbase',
    icon: <img src={icons.coinbase} {...iconImgProps} />,
  },
  19: {
    id: '19',
    code: 'external',
    name: 'Direct Employee',
    icon: <Invoice {...phProps} />,
  },
  wise: {
    id: 'wise',
    code: 'wise',
    name: 'Wise',
    icon: <img src={icons.wise} {...iconImgProps} />,
  },
}

function TransactionCard({ actions, order }) {
  const user = useSelector((state) => state.Account?.user)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: order?.currency?.code || 'USD',
  })

  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR
  const [showInfo, setShowInfo] = useState(false)

  return (
    <div
      className='py-0 mb-3 bg-white rounded position-relative'
      style={{
        boxShadow: '0px 1px 0px #DFE1E6',
        border: '1px solid #E7E8F2',
      }}
    >
      <ContractorStatusDescriptionModal
        isOpen={showInfo}
        toggle={() => setShowInfo(false)}
        order={order}
      />
      <div className='p-3 border-bottom d-flex justify-content-between align-items-center'>
        <div>
          <div className='d-flex align-items-center gap-8 mb-1'>
            <PaymentIcon method={order?.method} />

            <p className='text-gray-h font-size-14 mb-0'>
              {order.transaction_name}
            </p>
          </div>
          <p
            style={{ fontWeight: '400' }}
            className='text-primary font-size-12 mb-0'
          >
            {order.ref}
          </p>
        </div>
        <div
          className='d-flex justify-content-between'
          onClick={() => setShowInfo(true)}
        >
          <TransactionStatusBadge order={order} withIcon={!isClient} />
        </div>
      </div>
      <div className='p-3 d-flex flex-column' style={{ gap: '1rem' }}>
        <div className='d-flex align-items-center justify-content-between align-items-center'>
          <StyledH6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Date
          </StyledH6>
          <StyledH6 className='font-weight-normal mb-0 text-muted font-size-14'>
            {format(order.created_at * 1000, 'dd/MM/yyyy hh:mm')}
          </StyledH6>
        </div>
        <div className='d-flex mb-0 align-items-center justify-content-between align-items-center'>
          <StyledH6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Total
          </StyledH6>
          <StyledH6 className='text-muted font-size-14 font-weight-normal mb-0'>
            {formatter.format(order.amount)}
          </StyledH6>
        </div>
        {isClient || (isContractor && order?.details?.length > 0) ? (
          <div className='d-flex flex-wrap' style={{ gap: '0.5rem' }}>
            {actions.map(({ label, onClick, className }, index) => {
              return (
                <Button
                  size='sm'
                  color='light'
                  outline
                  onClick={onClick}
                  key={index}
                  className={className}
                >
                  {label}
                </Button>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

function getBodyFromFilters(filters, isClient) {
  const body = { page: filters.page, page_size: filters.page_size }

  if (filters.search_key) {
    body.search_key = filters.search_key
  }

  if (isClient) {
    body.month = filters.month?.value
  } else {
    body.year = filters.year?.value
  }

  return body
}

function Transactions() {
  const isMobile = useResize()

  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR

  const [showExportModal, setShowExportModal] = useState(false)
  const [showExportStatement, setShowExportStatement] = useState(false)

  const [filters, setFilters] = useState({
    month: null,
    year: null,
    search_key: null,
    page: 1,
    page_size: 10,
  })

  const [years, setYears] = useState([])

  const months = useFetch({
    action: getTransactionsMonths,
    autoFetch: true,
    onComplete: (data) => {
      if (data?.months?.length > 0) {
        const theMonth = data?.months[0]
        setFilters((prev) => ({ ...prev, month: theMonth }))

        if (isContractor) {
          const theYear = {
            label: data?.months[0].value.slice(0, 4),
            value: data?.months[0].value.slice(0, 4),
          }
          setFilters((prev) => ({ ...prev, year: theYear }))
        }

        // Get unique years using a Set
        const years = data.months.reduce((prev, curr) => {
          const newYear = curr?.value?.slice(0, 4)
          return prev.add(newYear)
        }, new Set())

        // set years as an array filtering out falsy values
        setYears(Array.from(years).filter(Boolean))
      }
    },
  })

  const {
    data: trx,
    isLoading: loadingTrx,
    paginator,
    startFetch: updateTrx,
  } = useFetch(
    {
      action: getPaymentHistory,
      autoFetch: true,
      body: getBodyFromFilters(filters, isClient),
    },
    [filters],
  )

  function handleUpdateTrx({ loading }) {
    updateTrx(getBodyFromFilters(filters, isClient), loading)
  }

  const handleSearch = (searchKey) => {
    setFilters((prev) => ({ ...prev, search_key: searchKey, page: 1 }))
  }

  return (
    <div className='page-content mb-lg-5'>
      <StyledH3 min='22px' max='32px' className='text-dark mb-3 mb-md-4'>
        Transactions
      </StyledH3>

      <Container fluid className='p-0 m-0'>
        <div
          className={isMobile ? null : 'bg-white rounded'}
          style={{ boxShadow: isMobile ? null : '0px 1px 0px #DFE1E6' }}
        >
          <Container fluid>
            {months.isLoading ? (
              <Row className='py-4 px-sm-3'>
                <Col xs={4} md={2} className='p-0 mb-3 mb-md-0'>
                  <Shimmer width='100%' height='42px' />
                </Col>
                <Col xs={8} md='auto' className='p-0 flex-grow-1'>
                  <Shimmer width='100%' height='42px' />
                </Col>
              </Row>
            ) : (
              <Row className='py-4 px-sm-3'>
                <Col xs={4} md={2} className='p-0 mb-3 mb-md-0'>
                  <CustomSelect
                    options={
                      isClient
                        ? months?.data?.months
                        : years.map((e) => ({ label: e, value: e }))
                    }
                    value={isClient ? filters.month : filters.year}
                    onChange={(value) => {
                      const key = isClient ? 'month' : 'year'
                      setFilters((prev) => ({ ...prev, [key]: value, page: 1 }))
                    }}
                    withSearch
                    searchable={false}
                    classNamePrefix='with-search'
                  />
                </Col>
                <Col xs={8} md='auto' className='p-0 flex-grow-1'>
                  <SearchBar
                    roundedLeftNone
                    query={filters?.search_key}
                    onQueryChanged={handleSearch}
                    className='mr-md-2'
                  />
                </Col>
                <div className='d-md-none w-100'></div>
                {isContractor && (
                  <div>
                    <button
                      className='btn btn-primary'
                      onClick={() => setShowExportStatement(true)}
                    >
                      Export Statement
                    </button>
                  </div>
                )}
              </Row>
            )}
          </Container>

          {!loadingTrx && trx?.transactions?.length === 0 ? (
            <div>
              <NoContent
                headline='Payments and Transactions'
                subtitle={
                  isClient
                    ? 'All your payment transactions will be shown here'
                    : 'All your payments & withdrawal transactions will be shown here'
                }
                image={noTransactionsImage}
              />
            </div>
          ) : (
            <>
              {isMobile ? (
                <Col
                  className='p-3 bg-white rounded'
                  style={{ boxShadow: '0px 1px 0px #DFE1E6' }}
                >
                  {loadingTrx ? (
                    <div
                      className='p-3 w-100'
                      style={{ '--s-height': '300px' }}
                    >
                      <Shimmer
                        width='100%'
                        height='var(--s-height)'
                        className='mb-2'
                      />
                      <Shimmer
                        width='100%'
                        height='var(--s-height)'
                        className='mb-2'
                      />
                      <Shimmer
                        width='100%'
                        height='var(--s-height)'
                        className='mb-2'
                      />
                    </div>
                  ) : (
                    trx?.transactions?.map((item, key) => (
                      <TransactionLine
                        item={item}
                        key={'_order_' + key}
                        handleUpdateTrx={handleUpdateTrx}
                      />
                    ))
                  )}
                </Col>
              ) : (
                <div
                  className='table-responsive'
                  style={{ paddingBottom: '5rem' }}
                >
                  {loadingTrx ? (
                    <div
                      className='px-3 w-100'
                      style={{ '--s-height': '57px' }}
                    >
                      <Shimmer
                        width='100%'
                        height='var(--s-height)'
                        className='mb-1'
                      />
                      <Shimmer
                        width='100%'
                        height='var(--s-height)'
                        className='mb-1'
                      />
                      <Shimmer width='100%' height='var(--s-height)' />
                    </div>
                  ) : (
                    <Table className='table-centered table-nowrap text-muted mb-0'>
                      <thead className='thead-light'>
                        <tr
                          style={{
                            borderTop: 'hidden',
                            borderBottom: '1px solid #E7E8F2',
                          }}
                        >
                          <StyledTh>
                            <p className='text-white mb-0'>.</p>
                          </StyledTh>
                          <StyledTh>Transaction ID</StyledTh>
                          <StyledTh style={{ width: '200px' }}>Date</StyledTh>
                          <StyledTh>Payment Method</StyledTh>
                          <StyledTh>Rate</StyledTh>
                          <StyledTh>Status</StyledTh>
                          {isClient && <th />}
                        </tr>
                      </thead>
                      <tbody>
                        {!loadingTrx
                          ? trx?.transactions?.map((item, key) => (
                              <TransactionLine
                                item={item}
                                key={'_order_' + key}
                                handleUpdateTrx={handleUpdateTrx}
                              />
                            ))
                          : null}
                      </tbody>
                    </Table>
                  )}
                </div>
              )}

              <div
                className={`mt-2 mt-md-0 d-flex justify-content-md-end p-3${
                  isMobile ? 'bg-white rounded' : ''
                }`}
              >
                <Pagination
                  innerClass='pagination mb-0'
                  activePage={filters?.page}
                  onChange={(page) => {
                    setFilters((prev) => ({ ...prev, page }))
                  }}
                  itemsCountPerPage={paginator?.per_page ?? 50}
                  totalItemsCount={paginator?.total ?? 0}
                />
              </div>
            </>
          )}
        </div>
      </Container>
      {showExportModal && (
        <FilterModal
          toggle={() => setShowExportModal(false)}
          isOpen={showExportModal}
          title='Export'
        />
      )}
      {showExportStatement && (
        <FilterModal
          toggle={() => setShowExportStatement(false)}
          isOpen={showExportStatement}
          title='Export Statement'
          action={exportStatement}
          statement
          fileName='Statement'
        />
      )}
    </div>
  )
}

function PaymentIcon({ method }) {
  const id = method?.id
  const name = method?.name

  const userType = useSelector((state) => state.Account?.user?.type)
  const isClient = userType === userTypes.COMPANY

  if (!isClient) {
    return null
  }

  const iconItem =
    name === 'Wise' ? PAYMENT_METHOD_TYPES.wise : PAYMENT_METHOD_TYPES[id]

  return (
    <span className='d-flex text-secondary-100'>{iconItem?.icon || null}</span>
  )
}

function TransactionLine({ item, handleUpdateTrx }) {
  const [expanded, setExpanded] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showAccountInfo, setShowAccountInfo] = useState(false)
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [downloadingReceipt, setDownloadingReceipt] = useState(false)
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [openDownloadModal, setOpenDownloadModal] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const isMobile = useResize()

  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR

  const formatter = getCurrencyFormatter(item?.currency)

  const details = useFetch({ action: getTrxDetails })

  const info = useFetch({ action: getTransactionDetails })

  const exportTrx = useFetch({
    action: exportTransaction,
    onError: toastr.error,
    onComplete: (data) => {
      openFile(data, `trx-statement-${item?.ref}.xls`)
    },
  })

  const { startFetch: confirmTransaction, isLoading: markingAsConfirmed } =
    useFetch({
      action: markDeTransactionAsConfirmed,
      onComplete: () => handleUpdateTrx({ loading: true }),
    })

  const { startFetch: downloadDEWSFile } = useFetch({
    action: downloadTransactionDEWSFile,
    onComplete: (data) =>
      openFileV2(data, {
        name: `DEWS-${item?.ref}.csv`,
        download: true,
      }),
  })

  const handleDownloadInvoice = () => {
    setOpenDownloadModal(true)
  }

  const handleClickInfo = (item) => {
    setShowAccountInfo(true)
    info.startFetch({ id: item?.id })
  }
  const handleClickTrxInfo = () => {
    setShowInfo(true)
  }
  const handleExport = (item) => {
    exportTrx.startFetch({ transaction_id: item?.id })
  }

  const createdAtDate = format(item.created_at * 1000, 'dd/MM/yyyy hh:mm')

  function handleDownloadWithdrawalReceipt(transaction) {
    setDownloadingReceipt(true)
    downloadWithdrawalReceipt(transaction.ref, user?.token, transaction.token)
      .then((r) => {
        openFile(r?.data, `receipt-${transaction?.ref}-${createdAtDate}.pdf`)
      })
      .catch(() => {
        toastr.error('Error downloading receipt', 'Error')
      })
      .finally(() => {
        setDownloadingReceipt(false)
      })
  }

  function handleCancelPayment() {
    setOpenCancelModal(true)
  }

  const actionsLoading =
    exportTrx.isLoading ||
    info.isLoading ||
    downloadingReceipt ||
    markingAsConfirmed

  const { hasAccess } = usePermissions()
  const canCancelPayment =
    item.status !== 'Paid' && hasAccess(permissions.revertTransactions)

  const hasContribution = item?.has_dews_contributions

  const actions = isContractor
    ? [
        {
          onClick: () => handleClickTrxInfo(),
          label: 'View details',
          icon: <Eye size={16} />,
        },
        item?.type?.id !== 2
          ? null
          : {
              onClick: downloadingReceipt
                ? null
                : () => handleDownloadWithdrawalReceipt(item),
              label: 'Download receipt',
              icon: <DownloadSimple size={16} />,
            },
      ]
    : [
        item.status !== 'Paid' &&
        String(item.method.id) !== PAYMENT_METHOD_TYPES[19].id
          ? {
              onClick: () => handleClickInfo(item),
              label: 'View bank details',
              icon: <Eye size={16} />,
            }
          : null,
        {
          onClick: () => handleDownloadInvoice(item),
          label: 'Download statement',
          icon: <DownloadSimple size={16} />,
        },
        hasContribution
          ? {
              onClick: () => downloadDEWSFile({ transaction_id: item.id }),
              label: 'Download DEWS file',
              icon: <DownloadSimple size={16} />,
            }
          : null,
        {
          onClick: () => handleExport(item),
          label: 'Export to CSV',
          icon: <FileCsv size={16} />,
        },
        canCancelPayment
          ? {
              className: 'tw-text-red',
              onClick: () => handleCancelPayment(item),
              label: 'Cancel payment',
              icon: <X size={16} />,
            }
          : null,
        item.status !== 'Paid' &&
          String(item.method.id) === PAYMENT_METHOD_TYPES[19].id && {
            label: 'Mark as confirmed',
            icon: <CheckCircle size={16} />,
            onClick: () => confirmTransaction({ transaction_id: item.id }),
          },
      ]

  return (
    <>
      {isMobile ? (
        <TransactionCard
          handleClickInfo={handleClickInfo}
          handleDownloadInvoice={handleDownloadInvoice}
          order={item}
          actions={actions.filter(Boolean)}
        />
      ) : (
        <>
          <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
            <td>
              {item?.works?.length !== 0 &&
                item.status !== 'Paid (off-cycle)' && (
                  <ExpandIcon
                    isExpanded={expanded}
                    className='rp-btn-nostyle d-flex p-1 text-dark'
                    onClick={() => setExpanded((t) => !t)}
                  />
                )}
            </td>
            <StyledTd className='text-dark'>{item.ref}</StyledTd>
            <StyledTd style={{ width: isClient ? null : 70 }}>
              {createdAtDate}
            </StyledTd>
            <StyledTd>
              <div className='d-flex align-items-center gap-8'>
                <PaymentIcon method={item?.method} />

                <span style={{ lineHeight: 1 }} className='text-secondary-80'>
                  {item.transaction_name}
                </span>
              </div>
            </StyledTd>

            <StyledTd>
              {formatter.format(isClient ? item.total : item.amount)}
            </StyledTd>

            <StyledTd>
              <TransactionStatusBadge
                order={item}
                withIcon={isContractor && item?.type?.id === 1}
                id={`trx_c${item?.ref}`}
              />

              {isContractor && item?.type?.id === 1 ? (
                <UncontrolledTooltip
                  placement='bottom'
                  target={`trx_c${item?.ref}`}
                >
                  {getTooltipText(item.status)}
                </UncontrolledTooltip>
              ) : null}
            </StyledTd>

            {(isClient || (isContractor && item?.details?.length > 0)) && (
              <td>
                <ActionsDropdown data={actions} loading={actionsLoading} />
              </td>
            )}
          </tr>

          {expanded && (
            <>
              {item?.works?.map((t) => {
                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: t?.currency || 'USD',
                })
                return (
                  <tr
                    style={{
                      backgroundColor: '#F4F5F7',
                      borderLeft: '1.5px solid  var(--primary)',
                      borderBottom: '1px solid #eee',
                    }}
                    className='font-weight-light font-size-12'
                    key={'_trans' + t?.id}
                  >
                    <th />
                    <StyledTd>{t.contract_ref}</StyledTd>
                    <StyledTd
                      colSpan={isClient ? 1 : 2}
                      style={{
                        width: isClient ? '80%' : 120,
                        marginBottom: 0,
                      }}
                    >
                      <div>
                        <p className='rp-font-bold'>{t?.name}</p>
                        <p
                          className='mb-0'
                          style={{
                            whiteSpace: t?.details.includes('Period from')
                              ? 'pre-line'
                              : 'inherit',
                          }}
                        >
                          {t?.details}
                        </p>
                        {t.attributes?.map((a) => (
                          <div key={`attr-${a?.name}`}>
                            <span className='text-muted'>{a?.name}: </span>
                            <span>{a?.value}</span>
                            <br />
                          </div>
                        ))}
                      </div>
                    </StyledTd>
                    {isClient ? (
                      <StyledTd>
                        <Col>
                          <Row className='align-items-center flex-nowrap'>
                            {!t.contractor?.photo ? (
                              <div className='avatar-xs'>
                                <span className='avatar-title rounded-circle'>
                                  {t.contractor?.first_name?.charAt(0)}
                                </span>
                              </div>
                            ) : (
                              <div>
                                <img
                                  className='rounded-circle avatar-xs'
                                  src={t.contractor?.photo}
                                  alt=''
                                />
                              </div>
                            )}
                            <div
                              className='font-size-14 ml-2 rp-font-bold text-gray-h'
                              style={{ marginLeft: 10 }}
                              translate='no'
                            >
                              {t.contractor?.first_name}{' '}
                              {t.contractor?.last_name}
                            </div>
                          </Row>
                        </Col>
                      </StyledTd>
                    ) : (
                      <StyledTh />
                    )}
                    <td>
                      <div>{formatter.format(t?.amount)}</div>
                    </td>
                    {isClient && <StyledTd />}
                    {isContractor && <td />}
                  </tr>
                )
              })}
            </>
          )}
        </>
      )}
      <Modal
        isOpen={showAccountInfo}
        toggle={() => {
          setShowAccountInfo(false)
        }}
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Account Info</h5>
          <button
            type='button'
            onClick={() => {
              setShowAccountInfo(false)
            }}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <AccountInfo loading={info.isLoading} data={info.data} />
        <div className='p-2' />
      </Modal>
      <Modal
        isOpen={showInfo}
        toggle={() => {
          setShowInfo(false)
        }}
        centered={true}
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Transaction Info</h5>
          <button
            type='button'
            onClick={() => {
              setShowInfo(false)
            }}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <AccountInfo data={{ data: item.details }} withCopy={false} />
        <div className='p-2' />
      </Modal>
      {isOpenDetail && (
        <DetailModal
          data={details.data}
          loading={details.isLoading}
          isOpen={isOpenDetail}
          toggle={() => setIsOpenDetail(false)}
        />
      )}

      {isClient ? (
        <>
          {canCancelPayment ? (
            <CancelPaymentModal
              isOpen={openCancelModal}
              toggle={() => setOpenCancelModal((open) => !open)}
              item={item}
              updateTrx={handleUpdateTrx}
            />
          ) : null}

          {openDownloadModal && (
            <DownloadStatementModal
              isOpen={openDownloadModal}
              toggle={() => setOpenDownloadModal((open) => !open)}
              item={item}
            />
          )}
        </>
      ) : null}
    </>
  )
}

export function FilterModal({
  isOpen,
  toggle,
  title,
  action = exportTransactions,
  fileName = 'transactions',
  statement,
}) {
  const [fileFormat, setFileFormat] = useState(null)
  const [type, setType] = useState(null)
  const [month, setMonth] = useState(null)
  const user = useSelector((state) => state?.Account?.user)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const getType = () => {
    switch (fileFormat?.value) {
      case 'excel':
        return 'xls'
      case 'csv':
        return 'csv'
      default:
        return 'pdf'
    }
  }
  const callBack = (r) => {
    const url = window.URL.createObjectURL(new Blob([r]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.${getType()}`) // or any other extension
    document.body.appendChild(link)
    link.click()
    toggle()
  }
  const exportTrx = useFetch({
    action,
    onComplete: callBack,
  })
  const handleExportTrx = () => {
    const error = validateChecks([
      [!((!fileFormat || !type) && !statement), 'Format and type are required'],
      ...(statement
        ? [
            [!!startDate, 'Start date is required!'],
            [!!endDate, 'End date is required!'],
          ]
        : [[!!month, 'Month is required!']]),
    ])

    if (error) {
      toastr.error(error)
      return
    }

    const body = !statement
      ? { month: format(month, 'yyyy-MM') }
      : {
          from: format(startDate, 'yyyy-MM-dd'),
          to: format(endDate, 'yyyy-MM-dd'),
        }

    if (!statement) {
      body.type = type?.value
      body.format = fileFormat?.value
    }
    exportTrx.startFetch(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>

      <ModalBody>
        {!statement && (
          <>
            <FormGroup>
              <label>Type</label>
              <CustomSelect
                options={[
                  { label: 'simple', value: 'simple' },
                  { label: 'detailed', value: 'detailed' },
                ]}
                onChange={setType}
                value={type}
              />
            </FormGroup>
            <FormGroup>
              <label>Format</label>
              <CustomSelect
                options={[
                  { label: 'excel', value: 'excel' },
                  { label: 'csv', value: 'csv' },
                ]}
                onChange={setFileFormat}
                value={fileFormat}
              />
            </FormGroup>
          </>
        )}
        {statement && user?.type !== 'client' ? (
          <>
            <FormGroup>
              <Label className='col-form-label pt-0 pt-0'>From</Label>
              <Col className='p-0'>
                <CustomDatePicker
                  value={startDate}
                  handleOnChange={setStartDate}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Label className='col-form-label pt-0 pt-0'>To</Label>
              <Col className='p-0'>
                <CustomDatePicker value={endDate} handleOnChange={setEndDate} />
              </Col>
            </FormGroup>
          </>
        ) : null}
        {!statement && (
          <FormGroup>
            <label>Month</label>
            <CustomDatePicker
              showMonthYearPicker
              placeholder='Month'
              dateFormat='MMM - yyyy'
              value={month}
              handleOnChange={setMonth}
            />
          </FormGroup>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={exportTrx.isLoading}
        >
          Cancel
        </Button>

        <Button
          onClick={handleExportTrx}
          disabled={exportTrx.isLoading}
          loading={exportTrx.isLoading}
        >
          Export
        </Button>
      </ModalFooter>
    </Modal>
  )
}
function ContractorStatusDescriptionModal({ isOpen, toggle, order }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      backdrop={true}
      contentClassName='align-self-end rounded-top pb-4'
      zIndex={INTERCOM_Z_INDEX_MAGIC_NUMBER}
      style={{
        height: 'auto',
        bottom: 0,
        position: 'absolute',
        transform: 'translateX(-50%)',
        left: '50%',
      }}
    >
      <ModalHeader toggle={toggle}>
        <div>
          <p
            style={{ fontWeight: 'bold' }}
            className='text-gray-h font-size-14 mb-0'
          >
            {order.transaction_name}
          </p>
          <p
            style={{ fontWeight: '400' }}
            className='text-primary font-size-12 mb-0'
          >
            {order.ref}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        <TransactionStatusBadge order={order} />
        <div className='text-muted px-1 pt-3'>
          {getTooltipText(order?.status)}
        </div>
      </ModalBody>
    </Modal>
  )
}

export function TransactionStatusBadge({ order, id, withIcon = false }) {
  return (
    <BadgeX
      status={getStatusColor(order?.status)}
      id={id}
      rightIcon={
        !withIcon ? null : <i className='bx bx-info-circle font-size-18' />
      }
      className='rp-capitalize'
    >
      {order?.status}
    </BadgeX>
  )
}

const clientCancelReasons = [
  { label: 'Add or remove a payment', value: 'add_or_remove' },
  { label: 'Change payment method', value: 'change_method' },
  { label: 'Other', value: 'other' },
]

const cancellationFormId = 'cancellationForm'

function CancelPaymentModal({ isOpen, toggle, item, updateTrx }) {
  const formatter = getCurrencyFormatter(item?.currency)

  const infoItems = [
    { label: 'Transaction ID', value: item?.ref },
    { label: 'Method', value: item?.method?.name },
    { label: 'Amount', value: formatter.format(item?.amount) },
  ]

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        reason: yup.string().required('Cancellation reason is required'),
      }),
    ),
  })

  const {
    startFetch: cancelTrx,
    isLoading: cancellingTrx,
    data,
  } = useFetch({
    action: clientCancelTransactions,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Error cancelling payment')
      }
    },
    onError: (err) => {
      const msg = typeof err === 'string' ? err : 'Error cancelling payment'
      toastr.error(msg)
    },
  })

  function handleCloseSuccess() {
    toggle?.()
    updateTrx?.({ loading: false })
  }

  function onSubmit(data) {
    const reasonLabel = clientCancelReasons.find(
      (r) => r.value === data.reason,
    )?.label

    cancelTrx({
      transaction_id: item?.id,
      reason: reasonLabel,
    })
  }

  const isCancelledSuccessful = Array?.isArray(data)

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      {isCancelledSuccessful ? (
        <SuccessModalContent closeSuccess={handleCloseSuccess} />
      ) : (
        <>
          <ModalHeader toggle={toggle}>Cancel Payment</ModalHeader>
          <ModalBody className='d-flex flex-column gap-24'>
            <form onSubmit={handleSubmit(onSubmit)} id={cancellationFormId}>
              <ControlledSelect
                label='Cancellation reason'
                control={control}
                error={errors?.reason}
                name='reason'
                options={clientCancelReasons}
              />
            </form>

            <hr className='border-surface-30 m-0 w-100' />

            <div className='d-flex flex-column gap-12 font-size-14'>
              {infoItems.map((item, key) => (
                <div className='d-flex justify-content-between' key={key}>
                  <span className='text-text-60'>{item.label}</span>
                  <span>{item.value}</span>
                </div>
              ))}
            </div>

            <hr className='border-surface-30 m-0 w-100' />

            <Alert
              customIcon={
                <Warning size={24} className='flex-shrink-0 text-red-110' />
              }
              color='danger'
              className='bg-red-20 border-surface-30 p-4 mb-0'
              innerClassName='text-text-80 font-size-14'
              style={{ gap: 'var(--size-16)' }}
            >
              All payments will be marked as unpaid and will become available
              for payment once again.
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button
              color='light'
              outline
              onClick={toggle}
              disabled={cancellingTrx}
            >
              Ignore
            </Button>
            <Button
              color='danger'
              type='submit'
              formId={cancellationFormId}
              disabled={cancellingTrx}
              loading={cancellingTrx}
            >
              Proceed
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

function SuccessModalContent({ closeSuccess }) {
  return (
    <>
      <ModalHeader
        toggle={closeSuccess}
        className='border-none'
        close={
          <button
            type='button'
            onClick={closeSuccess}
            className='close text-secondary-100 p-1'
            aria-label='Close'
            style={{ width: 'auto', height: 'auto' }}
          >
            <X size={24} />
          </button>
        }
      />

      <div className='d-flex flex-column align-items-center px-4 pt-4 pb-5 text-center'>
        <img src={trxCancelledIllustration} alt='' />

        <div>
          <h4>Transaction cancelled</h4>
          <p className='text-text-80 font-size-14' style={{ maxWidth: 424 }}>
            All payments are marked as unpaid and available for payment once
            again.
          </p>
        </div>

        <Button onClick={closeSuccess}>Go To Transactions</Button>
      </div>
    </>
  )
}

export default withTranslation()(Transactions)
