import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { useSelector } from 'react-redux'
import AsyncCreatableSelect from 'react-select/async-creatable'
import toastr from 'toastr'

import customStyles from '../../../components/Common/react-select-custom-styles'
import { DropdownIndicator } from '../../../components/Forms/CustomSelect/select-components'
import { getVendors } from '../../../services/api-bill-payments'
import { mapListToOption } from '../../../utils/map-to-option'
import { Controller } from 'react-hook-form'
import InputFeedback from '../../../components/ui/input-feedback'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'

const createNewOption = {
  value: null,
  label: 'Start typing to create a new vendor',
  isDisabled: true,
}

export function ControlledVendorSelect({
  control,
  inputId,
  label,
  placeholder,
  name,
  transform,
}) {
  const userToken = useSelector((state) => state.Account?.user?.token)

  const { mutate } = useMutation({
    mutationFn: (data) => {
      return getVendors(userToken, data)
    },
  })

  function loadOptions(inputValue, callback) {
    mutate(
      { search_key: inputValue },
      {
        onSettled: (data) => {
          const options = data?.data?.data?.map(mapListToOption)
          const allOptions = [...options, createNewOption]
          callback(allOptions)
        },
        onError: (error) => {
          const emptyData = [createNewOption]
          callback(emptyData)
          toastr.error(error)
        },
      },
    )
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange, ref },
        fieldState: { error },
      }) => {
        function handleChange(newValue) {
          typeof transform?.output === 'function'
            ? onChange(transform.output(newValue))
            : onChange(newValue ?? '')
        }

        return (
          <div>
            {!label ? null : <label htmlFor={inputId}>{label}</label>}

            <AsyncCreatableSelect
              cacheOptions
              loadOptions={loadOptions}
              components={{ IndicatorSeparator: null, DropdownIndicator }}
              placeholder={placeholder}
              defaultOptions
              name={name}
              onChange={handleChange}
              value={value}
              styles={customStyles}
              inputId={inputId}
              onBlur={onBlur}
              selectRef={ref}
            />

            {!error ? null : (
              <InputFeedback className='tw-mt-1'>
                {getInputErrorMessage(error)}
              </InputFeedback>
            )}
          </div>
        )
      }}
    />
  )
}
