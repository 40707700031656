import React, { useCallback, useRef } from 'react'
import ReactQuill from 'react-quill'
import { Controller } from 'react-hook-form'
import 'react-quill/dist/quill.snow.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { usePlaceholders } from './placeholders-context'
import Loader from '../../../../components/ui/loader'
import isNil from 'lodash/isNil'

export function ActionEmailBody(props) {
  const { index } = props
  const quillRef = useRef(null)
  const { isLoading, items } = usePlaceholders()

  const insertPlaceholder = useCallback((placeholder) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor()
      const cursorPosition = quill.getSelection()?.index
      if (!isNil(cursorPosition)) {
        quill.insertText(cursorPosition, `{{${placeholder}}}`, 'user')
        quill.setSelection(cursorPosition + `{{${placeholder}}}`.length)
      }
    }
  }, [])
  return (
    <Controller
      name={`steps.${index}.body`}
      render={({ field: { value = '', onChange } }) => (
        <div>
          <ReactQuill
            ref={quillRef}
            value={value}
            className='tw-flex tw-flex-col-reverse tw-rounded tw-border tw-border-surface-130 [&>*:nth-child(2)]:!tw-p-[15px]'
            onChange={onChange}
            placeholder='Email body'
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
              ],
            }}
          />

          {isLoading ? (
            <Loader minHeight='20px' className='tw-my-2 tw-flex-1' />
          ) : items?.length ? (
            <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-bg-surface-30 tw-px-2 tw-py-4'>
              {items?.map((p) => (
                <button
                  key={p.key}
                  onClick={() => {
                    insertPlaceholder(p.key)
                  }}
                  className='tw-rounded-full tw-bg-white tw-px-5 tw-py-2'
                >
                  {p.title}
                </button>
              ))}
            </div>
          ) : null}
        </div>
      )}
    ></Controller>
  )
}
