import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import { Input } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../components/ui/button'
import InputFeedback from '../../../components/ui/input-feedback'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch, useResize } from '../../../helpers/hooks'
import { referralLink, requestNewLink } from '../../../services/api'
import { getErrorMessage } from '../../../utils/get-errors'
import { cn } from 'ui'

export const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/
function isEmail(email) {
  return emailRegex.test(email)
}

function isInList(email, tags) {
  return tags.includes(email)
}

export default function SharingSection({
  showPreview,
  emails,
  setEmails,
  loading,
  sendInvitations,
}) {
  const user = useSelector((state) => state.Account?.user)
  const isMobile = useResize()
  const [sharingLink, setSharingLink] = useState(null)

  const { startFetch: requestSharingLink } = useFetch({
    action: requestNewLink,
    onComplete: (data) => {
      setSharingLink(data?.shareUrl)
    },
    onError: (err) => {
      toastr.error(getErrorMessage(err))
    },
  })
  useFetch({
    action: referralLink,
    autoFetch: true,
    body: { id: user?.id },
    onComplete: (data) => {
      setSharingLink(data?.shareUrl)
    },
    onError: (err) => {
      if (err === 'Not Found') {
        requestSharingLink({
          id: user?.id,
          email: user?.email,
          firstName: user?.first_name,
          lastName: user?.last_name,
          type: user?.type,
        })
      } else {
        toastr.error(getErrorMessage(err))
      }
    },
  })

  function copyToClipboard(value) {
    copy(value)
    toastr.info('Link copied!')
  }

  return (
    <div
      className='d-flex card mb-0 rp-shadow-2 p-2'
      style={{ width: isMobile ? '100%' : '59%' }}
    >
      {sharingLink ? (
        <div className='p-2'>
          <p className='mb-2 font-size-24 font-weight-600'>
            Refer RemotePass to your network today!
          </p>
          <p className='mb-2 font-size-13 font-weight-500'>
            Copy the link and Share!
          </p>

          <div className='position-relative mb-3'>
            <Input
              className='form-control d-flex align-items-center justify-content-between bg-white'
              value={sharingLink}
              readOnly
            />

            <i
              className='bx bxs-copy position-absolute p-1 font-size-14 tw-right-3 tw-top-1/2 -tw-translate-y-1/2'
              style={{ cursor: 'pointer' }}
              onClick={() => copyToClipboard(sharingLink, 'Link')}
            />
          </div>

          <div className='text-muted mb-2'>SHARE LINK ON</div>

          <div className='d-flex gap-8'>
            <TwitterShareButton
              title='@RemotePassCom has revolutionized our remote payroll and onboarding. They’re giving one month free for new companies — try it out today! 👉'
              url={sharingLink}
            >
              <Button
                color='rgba(231, 232, 242, 0.5)'
                className='px-4 py-2'
                style={{ backgroundColor: 'rgba(231, 232, 242, 0.5)' }}
              >
                <i
                  className='bx bxl-twitter font-size-24'
                  style={{ color: 'rgba(66, 103, 178, 1)' }}
                />
              </Button>
            </TwitterShareButton>
            <FacebookShareButton url={sharingLink}>
              <Button
                color='rgba(231, 232, 242, 0.5)'
                className='px-4 py-2'
                style={{ backgroundColor: 'rgba(231, 232, 242, 0.5)' }}
              >
                <i
                  className='bx bxl-facebook font-size-24'
                  style={{ color: 'rgba(66, 103, 178, 1)' }}
                />
              </Button>
            </FacebookShareButton>
            <LinkedinShareButton url={sharingLink}>
              <Button
                color='rgba(231, 232, 242, 0.5)'
                className='px-4 py-2'
                style={{ backgroundColor: 'rgba(231, 232, 242, 0.5)' }}
              >
                <i
                  className='bx bxl-linkedin-square font-size-24'
                  style={{ color: 'rgba(66, 103, 178, 1)' }}
                />
              </Button>
            </LinkedinShareButton>
          </div>

          <hr />

          <div>
            <label>Invite by email</label>

            <TagsInput className='tw-mb-3' tags={emails} setTags={setEmails} />
          </div>
          <div
            className='d-flex align-items-center justify-content-end pt-1'
            style={{ gap: '1.5rem' }}
          >
            <Button
              outline
              color='secondary'
              onClick={() => showPreview(true)}
              disabled={loading || emails.length <= 0}
            >
              Preview email
            </Button>
            <Button
              loading={loading}
              onClick={sendInvitations}
              disabled={loading || emails.length <= 0}
            >
              Send email
            </Button>
          </div>
        </div>
      ) : (
        <Shimmer width='100%' height='100%' />
      )}
    </div>
  )
}
export function TagsInput({ tags, setTags, className, placeholder }) {
  const [emValue, setEmailValue] = useState('')
  const [error, setError] = useState(null)

  function isValid(email) {
    let error = null

    if (isInList(email, tags)) {
      error = `${email} has already been added.`
    }

    if (!isEmail(email)) {
      error = `"${email}" is not a valid email address.`
    }

    if (error) {
      setError(error)

      return false
    }

    return true
  }
  function handleKeyDown(evt) {
    if ([',', ' '].includes(evt.key)) {
      evt.preventDefault()

      const value = emValue.trim()

      if (value && isValid(value)) {
        setTags([...tags, emValue])
        setEmailValue('')
      }
    }
  }
  function handleChange(evt) {
    setEmailValue(evt.target.value)
    setError(null)
  }
  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
  }

  return (
    <div className={className}>
      <div className='d-flex gap-8 mb-2'>
        {tags.map((tag, index) => (
          <div className='email-tag-item' key={index}>
            <span className='text'>{tag}</span>
            <span className='close' onClick={() => removeTag(index)}>
              x
            </span>
          </div>
        ))}
      </div>

      <Input
        value={emValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        type='text'
        placeholder={placeholder ?? 'Enter email addresses separated by comma'}
      />

      {!error ? null : (
        <InputFeedback className='tw-mt-2'>{error}</InputFeedback>
      )}
    </div>
  )
}
