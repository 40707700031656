import React from 'react'
import { Box, BoxIcon } from '../../../components/ui/box'
import { Info } from '@phosphor-icons/react'
import { UncontrolledTooltip } from 'reactstrap'

export default function DefaultApprovalFlow({
  icon,
  cta,
  description,
  tip,
  infoId,
}) {
  return (
    <>
      <Box noPadding className='tw-flex-1'>
        <div className='tw-flex tw-items-center tw-gap-4 tw-p-4'>
          <BoxIcon>{icon}</BoxIcon>

          <div>
            <span className='tw-flex tw-gap-2'>
              <p className='tw-mb-0 tw-font-bold tw-text-black'>Default flow</p>
              {tip && (
                <>
                  <Info size={20} className='tw-fill-secondary' id={infoId} />
                  <UncontrolledTooltip
                    target={infoId}
                  >{`Contracts are assigned to the default approval, where any of the assigned approvers can approve ${tip}.`}</UncontrolledTooltip>
                </>
              )}
            </span>
            <p className='tw-mb-0 tw-mt-0.5 tw-text-secondary-100'>
              Assigned users can approve contract items
            </p>
          </div>
        </div>
        <div className='tw-flex tw-justify-between tw-border-t tw-border-surface-30 tw-bg-surface-10 tw-p-6'>
          <p className='tw-mb-0 tw-mt-0.5 tw-text-text-80'>{description}</p>
          {cta}
        </div>
      </Box>
    </>
  )
}
