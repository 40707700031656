import lowerCase from 'lodash/lowerCase'
import { triggers } from './constants'
import set from 'lodash/set'
import compact from 'lodash/compact'
import upperCase from 'lodash/upperCase'
import {
  updateAutomation as updateAutomationApi,
  updateSteps as updateStepsApi,
} from '../../../../services/api-automations'
import isEqual from 'lodash/isEqual'
import reduce from 'lodash/reduce'
import mapValues from 'lodash/mapValues'
import isEmpty from 'lodash/isEmpty'
import entries from 'lodash/entries'
import mapKeys from 'lodash/mapKeys'

export const defaultValue = {
  steps: [{ type: 'trigger' }, { type: 'action' }],
  name: 'Untitled automation',
  isPublished: false,
  description: '',
}

export function transformAutomationResponse(res) {
  const transformSteps = (_steps) => {
    let hasTrigger = false
    let hasAction = false
    let filters
    const steps = _steps.map((s) => {
      const type = lowerCase(s.type)
      const step = { type }
      if (type === 'trigger') {
        const trigger = triggers.find((t) => t.value === s.trigger.event)
        set(step, 'event', trigger)
        filters = mapKeys(
          mapValues(s?.trigger?.filters ?? {}, (f) => f.value),
          (value, key) => key.replaceAll('.', '$'),
        )

        hasTrigger = true
      }

      if (type === 'action') {
        const message = s?.action?.customMessage
        const targets = s?.action?.notificationTargets ?? []
        set(step, 'subject', message?.emailSubject ?? '')
        set(step, 'body', message?.emailBody ?? '')
        set(
          step,
          'to',
          targets.map((t) => t.email),
        )
        hasAction = true
      }

      if (type === 'delay') {
        const minute = s.delay.minutes
        set(step, 'numberOfDays', minute)
      }
      return step
    })
    if (!hasTrigger) steps.unshift(defaultValue.steps[0])
    if (!hasAction) steps.push(defaultValue.steps[1])
    return { steps, filters }
  }
  const { steps, filters } = transformSteps(res?.steps ?? [])
  return {
    name: res?.name ?? '',
    filters,
    description: res?.description ?? '',
    isPublished: res?.isPublished ?? false,
    steps,
  }
}

export function transformStepsRequest(_steps, _filters) {
  return compact(
    _steps.map((s) => {
      const step = { type: upperCase(s.type) }
      if (s.type === 'trigger') {
        const value = s?.event?.value
        if (!value) return null
        set(step, 'trigger.event', s?.event?.value)
        if (!isEmpty(_filters))
          set(
            step,
            'trigger.filters',
            reduce(
              entries(_filters),
              (prev, [name, value]) => ({
                ...prev,
                [name.replaceAll('$', '.')]: { value, operator: 'in' },
              }),
              {},
            ),
          )
      }
      if (s.type === 'action') {
        const emailSubject = s?.subject
        const emailBody = s?.body
        const to = s?.to ?? []
        if (!emailBody || !emailSubject) return null
        set(step, 'action.type', 'SEND')
        set(step, 'action.channel', 'EMAIL')
        set(
          step,
          'action.notificationTargets',
          to.map((t) => ({
            email: t,
            name: t.split('@')[0],
          })),
        )
        set(step, 'action.customMessage', {
          emailSubject,
          emailBody,
        })
      }

      if (s.type === 'delay') {
        if (!s.numberOfDays) return null
        set(step, 'delay.minutes', s.numberOfDays)
      }
      return step
    }),
  )
}

export function transformFilters(array) {
  return reduce(
    array,
    (acc, item) => {
      const key = item.key.replaceAll('.', '$')
      acc.all.push({
        title: item.title,
        filter: key,
      })
      acc[key] = item.available_values
      return acc
    },
    { all: [] },
  )
}

export async function updateAutomation(
  token,
  { id, steps, name, isPublished, currentAutomation, filters },
) {
  const stepsRequest = transformStepsRequest(steps, filters)
  const currentRequest = transformStepsRequest(
    currentAutomation.steps,
    currentAutomation.filters,
  )
  const isStepsSame = isEqual(stepsRequest, currentRequest)
  const isAutomationSame = isEqual(
    { name, isPublished },
    {
      name: currentAutomation.name,
      isPublished: currentAutomation.isPublished,
    },
  )
  const promises = []
  if (!isStepsSame)
    promises.push(updateStepsApi(token, { steps: stepsRequest, id }))
  if (!isAutomationSame)
    promises.push(updateAutomationApi(token, { id, name, isPublished }))

  return await Promise.all(promises)
}
