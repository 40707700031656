import { yupResolver } from '@hookform/resolvers/yup'
import {
  ArrowCircleDown,
  ArrowCircleUp,
  CalendarBlank,
  ClockClockwise,
  Info,
  MinusCircle,
  TreePalm,
} from '@phosphor-icons/react'
import { format, isValid } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toastr from 'toastr'
import * as yup from 'yup'

import { cn } from 'ui'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledSelect from '../../../components/ControlledSelect'
import { PermissionTooltip } from '../../../components/permission-tooltip'
import PolicyAccrualInfo from '../../../components/policy-accrual-info'
import PolicyRequestSettings from '../../../components/policy-request-settings'
import { PrimaryAlert } from '../../../components/ui/alert'
import BadgeV2 from '../../../components/ui/badge-v2'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import {
  SideMenu,
  SideMenuBody,
  SideMenuFooter,
  SideMenuHeader,
} from '../../../components/ui/side-menu'
import WorkingDaysView from '../../../components/working-days-view'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  assignContractsToPolicy,
  getContractTimeOff,
  getPolicyCyclesPreview,
  getTimeOffPolicies,
} from '../../../services/api-time-off-policies'
import { track } from '../../../utils/analytics'
import { DATE_PICKER_FORMAT_2 } from '../../../utils/formatters/date-picker-date-format'
import { formatDays } from '../../../utils/formatters/format-days'
import isNill from '../../../utils/is-nill'
import {
  EARNING_DATE_TYPE,
  labelFromType,
} from '../../CompanySetting/components/time-off-policies-tab/manage-policy-workers'
import { DetailsList } from '../../CompanySetting/components/time-off-policies-tab/view-policy'
import { PERMISSION_GROUP } from '../../CompanySetting/manage-role'
import { TIMEOFF_EVENTS } from '../../new-time-off-policy/events'
import { ConfirmFormField } from '../CreateContract/components/confirm-field'
import {
  DEFAULT_TIME_OFF_ID,
  TimeOffPolicyCarryOverTypes,
} from '../utils/constants'
import { POLICY_ACTION_TYPE } from './time-off-stats'
import ControlledInput from '../../../components/ControlledInput'

const assignPolicyButtonId = 'add-time-off-policy-btn'
const assignPolicyFormId = 'assign-policy-form'

export function AssignPolicyAction({ contract, children, onSuccess }) {
  const [isOpen, setIsOpen] = useState()
  function toggleMenu() {
    setIsOpen((open) => {
      // when closing the menu, we need to reset the form
      if (!open) {
        reset()
      }
      return !open
    })
  }

  const contractId = contract?.id

  const { hasAccess } = usePermissions()
  const canManageCompanySettings = hasAccess(permissions.manageContractSettings)

  const {
    data: policies,
    isLoading: policiesLoading,
    completed: policiesCompleted,
  } = useFetch(
    {
      action: getTimeOffPolicies,
      autoFetch: isOpen,
      onError: (error) => {
        toastr.error(error)
      },
    },
    [isOpen],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(
      yup.object().shape({
        policy_id: yup.number().required('Please select a policy'),
        earning_date: yup
          .string()
          .nullable()
          .test({
            name: 'earning_date_test',
            test: (value, { options }) => {
              const localSelectedPolicy = policies?.find(
                (p) => p.id === options?.parent?.policy_id,
              )
              const isLocalSelectedPolicyAccrued =
                localSelectedPolicy?.type?.is_accrued === 1

              // If the selected policy is accrued, the earning date is required
              const earningDateIsRequired =
                !value && isLocalSelectedPolicyAccrued

              return !earningDateIsRequired
            },
            message: 'Please select an option',
          }),
        custom_earning_date: yup.date().when('earning_date', {
          is: EARNING_DATE_TYPE.CUSTOM,
          then: (schema) => schema.required('Please select a custom date'),
          otherwise: (schema) => schema.nullable(),
        }),
        [EARNING_DATE_TYPE.EARNING_START_DAYS]: yup
          .number()
          .typeError('Please insert a number')
          .when('earning_date', {
            is: EARNING_DATE_TYPE.EARNING_START_DAYS,
            then: (schema) => schema.required('Please insert amount of days'),
            otherwise: (schema) => schema.notRequired(),
          }),
      }),
    ),
  })

  const {
    policy_id: policyId,
    earning_date: earningDate,
    custom_earning_date: customEarningDate,
  } = useWatch({ control })

  const selectedPolicy = policies?.find((p) => p.id === policyId)
  const isSelectedPolicyAccrued = selectedPolicy?.type?.is_accrued === 1
  const isCustomEarningDate = earningDate === EARNING_DATE_TYPE.CUSTOM

  const { startFetch: assignPolicy, isLoading: loading } = useFetch({
    action: assignContractsToPolicy,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error('Failed to assign policy')
      } else {
        toastr.success('Policy assigned successfully')
        toggleMenu()
        onSuccess?.()
        track(TIMEOFF_EVENTS.ASSIGNED_WORKER, {
          policy_type: selectedPolicy.type.name,
          accrual_frequency: selectedPolicy.accrual_frequency ?? null,
          carryover_used:
            selectedPolicy.carryover_type ===
            TimeOffPolicyCarryOverTypes.LIMITED,
          source: 'Contract',
          custom_accrual_date: isCustomEarningDate,
        })
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function onSubmit(values) {
    let startDate
    let days

    if (values.earning_date === EARNING_DATE_TYPE.CUSTOM) {
      startDate = format(new Date(values.custom_earning_date), 'yyyy-MM-dd')
    }

    if (values.earning_date === EARNING_DATE_TYPE.EARNING_START_DAYS) {
      days = values[EARNING_DATE_TYPE.EARNING_START_DAYS]
    }

    assignPolicy({
      policyId: values.policy_id,
      contracts: [contractId],
      start_date: startDate,
      [EARNING_DATE_TYPE.EARNING_START_DAYS]: days,
    })
  }

  const { data: existingPolicies, isLoading: existingPoliciesLoading } =
    useFetch(
      {
        action: getContractTimeOff,
        body: { contract_id: contractId },
        autoFetch: !!contractId && isOpen,
        onError: (error) => {
          toastr.error(error)
        },
      },
      [contractId, isOpen],
    )

  const policyOptions = policies
    ?.filter((policy) => {
      const policyAlreadyAssigned = !existingPolicies
        ?.map((p) => p.policy.id)
        .includes(policy.id)
      const policyTypeAlreadyAssigned = !existingPolicies
        ?.map((p) => p.type.id)
        .includes(policy.type.id)

      return policyAlreadyAssigned && policyTypeAlreadyAssigned
    })
    ?.map((policy) => {
      return {
        label: policy.name,
        value: policy.id,
        description: `${policy.type.name} | ${
          policy.type.is_accrued ? 'Accrual' : 'Non-accrual'
        }`,
      }
    })

  const formattedContractStartDate = isValid(new Date(contract?.start_date))
    ? format(new Date(contract?.start_date), 'yyyy-MM-dd')
    : ''

  const { data: cyclesPreview, isLoading: cyclesPreviewLoading } = useFetch(
    {
      action: getPolicyCyclesPreview,
      autoFetch:
        isSelectedPolicyAccrued &&
        (!isCustomEarningDate || (isCustomEarningDate && customEarningDate)),
      body: {
        id: selectedPolicy?.id,
        start_date: isCustomEarningDate
          ? customEarningDate
          : formattedContractStartDate,
        policy_id: policyId,
        contract_id: contractId,
      },
    },
    [selectedPolicy?.id, earningDate, customEarningDate, isCustomEarningDate],
  )

  const requiresCustomEarningDate = isCustomEarningDate && !customEarningDate

  let hasDefaultPolicy = false
  let accrualPolicy

  if (existingPolicies) {
    for (const { policy, is_accrual: isAccrual } of existingPolicies) {
      if (policy.id === DEFAULT_TIME_OFF_ID) {
        hasDefaultPolicy = true
      }
      if (isAccrual) {
        accrualPolicy = policy
      }
    }
  }

  return (
    <>
      <PermissionTooltip
        showing={!canManageCompanySettings}
        id={`${assignPolicyButtonId}-tooltip`}
        area={PERMISSION_GROUP.CONTRACT_SETTINGS.name}
      >
        {typeof children === 'function' ? (
          children({
            disabled: !canManageCompanySettings,
            onClick: () => toggleMenu(),
            id: assignPolicyButtonId,
          })
        ) : (
          <Button
            disabled={!canManageCompanySettings}
            id={assignPolicyButtonId}
            color='light'
            outline
            onClick={toggleMenu}
            className='!tw-px-6'
          >
            Assign policy
          </Button>
        )}
      </PermissionTooltip>

      <SideMenu
        itemListClassName='tw-grid [&>*:nth-child(2)]:tw-overflow-auto [&>*:nth-child(2)]:tw-overscroll-contain tw-grid-rows-[minmax(auto,max-content)_1fr_91px]'
        isOpen={isOpen}
        onClose={toggleMenu}
        className='tw-max-w-[520px]'
      >
        <SideMenuHeader toggle={toggleMenu}>
          Assign time off policy
        </SideMenuHeader>
        <SideMenuBody className='tw-flex-grow !tw-p-0'>
          {policiesLoading || !policiesCompleted || existingPoliciesLoading ? (
            <Loader minHeight='100%' />
          ) : (
            <form
              id={assignPolicyFormId}
              className='tw-p-6'
              onSubmit={handleSubmit(onSubmit)}
            >
              <ControlledSelect
                control={control}
                name='policy_id'
                label='Policy'
                options={policyOptions}
                placeholder='Select policy'
                transform={{
                  output: (newValue) => {
                    const foundPolicy = policies?.find(
                      (policy) => policy.id === newValue?.value,
                    )
                    // Default accrued policy to contract start date
                    if (foundPolicy?.type?.is_accrued === 1) {
                      setValue(
                        'earning_date',
                        EARNING_DATE_TYPE.EARNING_START_DAYS,
                      )
                    } else {
                      setValue('earning_date', null)
                    }
                    setValue('custom_earning_date', null)
                    setValue(
                      EARNING_DATE_TYPE.EARNING_START_DAYS,
                      foundPolicy?.[EARNING_DATE_TYPE.EARNING_START_DAYS],
                    )
                    return newValue
                  },
                }}
              />

              {selectedPolicy?.is_accrual && !!accrualPolicy && (
                <PrimaryAlert className='tw-my-6'>
                  {`This policy will replace the ${
                    hasDefaultPolicy
                      ? 'current Default Time Off'
                      : accrualPolicy.name
                  } policy.
                  Past time off taken will be included in the new policy.`}
                </PrimaryAlert>
              )}

              <AnimatePresence mode='wait'>
                {!policyId ? null : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    key={selectedPolicy?.id}
                  >
                    <DetailsList
                      className='tw-px-0'
                      title='Policy details'
                      titleClassName='tw-px-0'
                      bodyClassName='tw-px-0'
                      items={[
                        {
                          icon: <TreePalm size={24} />,
                          label: 'Type',
                          value: selectedPolicy?.type?.name,
                        },
                        isSelectedPolicyAccrued && {
                          icon: <CalendarBlank size={24} />,
                          label: 'Time off amount',
                          value:
                            formatDays(selectedPolicy?.accrual_days) +
                            ' / Year',
                        },
                        {
                          icon: <ClockClockwise size={24} />,
                          label: 'Accrual frequency',
                          value: selectedPolicy?.accrual_frequency
                            ? labelFromType(selectedPolicy?.accrual_frequency)
                            : 'Non-Accrued',
                        },
                      ]}
                    />

                    <hr className='-tw-mx-6 tw-my-6' />
                    <WorkingDaysView days={selectedPolicy.working_days} />
                    <hr className='-tw-mx-6 tw-my-6' />

                    {isSelectedPolicyAccrued && (
                      <PolicyAccrualInfo
                        policy={selectedPolicy}
                        bodyClassName='tw-px-0'
                      />
                    )}

                    <PolicyRequestSettings
                      isOpenByDefault
                      policy={selectedPolicy}
                      bodyClassName='tw-px-0'
                    />

                    {!isSelectedPolicyAccrued ? null : (
                      <>
                        <hr className='-tw-mx-6 tw-my-6' />
                        <ConfirmFormField
                          control={control}
                          error={errors.earning_date}
                          name='earning_date'
                          title='Earning date'
                          description='When does the worker start earning?'
                          fieldOptions={[
                            {
                              label: 'Days after contract start',
                              value: EARNING_DATE_TYPE.EARNING_START_DAYS,
                            },
                            {
                              label: 'Custom date',
                              value: EARNING_DATE_TYPE.CUSTOM,
                            },
                          ]}
                          className='tw-mt-3'
                          innerClassName={
                            isCustomEarningDate && 'tw-rounded-b-none'
                          }
                          transform={{
                            output: (event) => {
                              setValue('custom_earning_date', null)
                              setValue('earning_start_days', null)

                              return event
                            },
                          }}
                        />
                        <div className='tw-rounded-b tw-border tw-border-t-0 tw-border-surface-30 tw-bg-surface-10 tw-p-4'>
                          {!isCustomEarningDate ? (
                            <>
                              <ControlledInput
                                control={control}
                                name={EARNING_DATE_TYPE.EARNING_START_DAYS}
                                postFix='Calendar days'
                                type='number'
                                placeholder='Insert amount'
                              />
                              <div className='tw-mt-2 tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-text-80'>
                                <Info size={16} />
                                <span>
                                  Policy setting:{' '}
                                  {selectedPolicy?.[
                                    EARNING_DATE_TYPE.EARNING_START_DAYS
                                  ] ?? 0}{' '}
                                  days after the contract start date
                                </span>
                              </div>
                            </>
                          ) : (
                            <ControlledDatePicker
                              control={control}
                              name='custom_earning_date'
                              id='custom_earning_date'
                              placeholder='Earning date'
                              label='Earning date'
                              dateFormat={DATE_PICKER_FORMAT_2}
                            />
                          )}
                        </div>

                        <hr className='-tw-mx-6 tw-my-6' />
                        <h4 className='tw-text-base tw-font-bold'>
                          Balance preview
                        </h4>

                        <BalanceItem
                          note='Time off balance'
                          created='Once it’s assigned'
                          newBalance={cyclesPreview?.balance_on_assigning}
                          isBalanceUnlimited={!isSelectedPolicyAccrued}
                        />

                        <hr className='-tw-mx-6 tw-my-6' />
                        <h4 className='tw-text-base tw-font-bold'>
                          Accrual balance preview
                        </h4>

                        {!earningDate || requiresCustomEarningDate ? (
                          <div className='tw-h-[390px] tw-pt-4'>
                            Please select an earning date
                          </div>
                        ) : cyclesPreviewLoading ? (
                          <div className='tw-h-[390px] tw-pt-4'>
                            Loading accrual balance preview ...
                          </div>
                        ) : (
                          <PolicyBalancePreview
                            cyclesPreview={cyclesPreview?.cycles}
                          />
                        )}
                      </>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </form>
          )}
        </SideMenuBody>

        <SideMenuFooter className='tw-items-center'>
          <Button
            color='light'
            outline
            disabled={loading}
            onClick={toggleMenu}
            type='button'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={loading || !policyId}
            loading={loading}
            formId={assignPolicyFormId}
          >
            Assign
          </Button>
        </SideMenuFooter>
      </SideMenu>
    </>
  )
}

export function PolicyBalancePreview({ cyclesPreview }) {
  return (
    <div>
      {cyclesPreview?.map(
        (
          { note, credited_at: creditedAt, balance, days: accrualDays, type },
          index,
        ) => {
          const creditedAtFrom = creditedAt
            ? format(new Date(creditedAt), 'dd/MM/yyyy')
            : null

          return (
            <BalanceItem
              key={index}
              note={note}
              created={creditedAtFrom}
              days={accrualDays}
              type={type}
              newBalance={balance}
            />
          )
        },
      )}
    </div>
  )
}

export function getBalanceAccessories(action, days) {
  if (!days) {
    return {
      className: '!tw-bg-secondary-20 !tw-text-secondary',
      icon: <MinusCircle weight='fill' size={16} />,
    }
  }

  switch (action) {
    case POLICY_ACTION_TYPE.ADDITION:
      return {
        className: '!tw-bg-systemGreen-10 !tw-text-systemGreen',
        icon: <ArrowCircleUp weight='fill' size={16} />,
      }
    case POLICY_ACTION_TYPE.DEDUCTION:
      return {
        className: '!tw-bg-systemRed-10 !tw-text-systemRed',
        icon: <ArrowCircleDown weight='fill' size={16} />,
      }
  }
}

export function BalanceItem({
  note,
  created,
  days,
  isBalanceUnlimited,
  type,
  newBalance,
}) {
  const { className, icon } = getBalanceAccessories(type, days)
  return (
    <div className='tw-flex tw-justify-between tw-gap-2 tw-border-b tw-border-b-surface-30 tw-py-4'>
      <div>
        <div>{note}</div>
        {!created ? null : <div className='tw-text-text-80'>{created}</div>}
      </div>

      <div className='tw-text-end'>
        {isBalanceUnlimited ? (
          <div>Unlimited</div>
        ) : (
          <div className='tw-flex tw-items-center tw-justify-end tw-gap-2'>
            {isNill(days) ? null : (
              <BadgeV2
                className={cn(className, 'tw-self-start')}
                textClassName='tw-text-sm'
                rightIcon={icon}
              >
                {days ? parseFloat(days).toFixed(2) : days}
              </BadgeV2>
            )}
            {!newBalance && newBalance !== 0 ? null : (
              <div className='tw-text-sm tw-font-semibold'>
                {formatDays(parseFloat(newBalance).toFixed(2))}
              </div>
            )}
          </div>
        )}

        <div className='tw-text-text-80'>Balance</div>
      </div>
    </div>
  )
}
